import React, { FC, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { FormApi } from "final-form";
import DefaultForm from "../../../../components/form/DefaultForm";
import { useApi } from "../../../../hooks/useApi";
import { MessageType } from "../../SupportChatType";
import ChatFootersFields from "./ChatFootersFields";

type FormType = {
  body: string;
  files: Blob[];
};

type ChatFooterProps = {
  handleScrollToBottom: () => void;
  handleAddMessage: (message: MessageType) => void;
};

const ChatFooter: FC<ChatFooterProps> = ({
  handleScrollToBottom,
  handleAddMessage,
}) => {
  const { id } = useParams();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: FormType, form: FormApi) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (values.body.trim() !== "") {
        formData.append("body", values.body);
      }

      if (!!values.files.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < values.files.length; i++) {
          formData.append("files[]", values.files[i]);
        }
      }

      const { data } = await api.post(`/threads/${id}/messages`, formData);

      form.reset();
      handleAddMessage(data);
      handleScrollToBottom();
      setLoading(false);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <DefaultForm<FormType>
      data={{
        body: "",
        files: [],
      }}
      onSubmit={onSubmit}
    >
      <ChatFootersFields loading={loading} />
    </DefaultForm>
  );
};

export default ChatFooter;
