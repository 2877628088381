import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { FC } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type Props = DialogProps & {
  title: string;
  body: string;
  onConfirm: () => void;
  onCancel: () => void;
  summitBtnText?: string;
  canselBtnText?: string;
};

const ConfirmModal: FC<Props> = ({
  title,
  body,
  onConfirm,
  onCancel,
  summitBtnText = "Yes",
  canselBtnText = "No",
  ...props
}) => {
  return (
    <Dialog {...props}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{canselBtnText}</Button>
        <Button onClick={onConfirm} autoFocus>
          {summitBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
