import React, { FC } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ChatIcon from "@mui/icons-material/Chat";
import { useModal } from "mui-modal-provider";
import { UsersType } from "./Users.type";
import ConfirmModal from "../../components/modals/ConfirmModal";

type SubscribersRowProps = {
  data: UsersType;
  handleDeleteUser: (id: number) => void;
  handleOpenChat: (id: number) => void;
};

const UsersRow: FC<SubscribersRowProps> = ({
  data,
  handleDeleteUser,
  handleOpenChat,
}) => {
  const { showModal } = useModal();

  const handleOpenConfirmationDialog = (id: number, name: string) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete Message ?`,
      body: `Are you sure you want to delete ${name}?`,
      onConfirm: () => {
        handleDeleteUser(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.full_name}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.email ? data.email : "-"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.phone ? data.phone : "-"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.birth_day ? data.birth_day : "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.package}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.last_activated_at}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.last_login_at}</Typography>
      </TableCell>

      <TableCell size="small" align="center" component="th" scope="row">
        <Box>
          <IconButton onClick={() => handleOpenChat(data.id)} size="small">
            <ChatIcon />
          </IconButton>
          <IconButton size="small" component={Link} to={`/users/${data.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              handleOpenConfirmationDialog(data.id, data.full_name)
            }
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UsersRow;
