import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const NoFindPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Box
        sx={{
          width: "500px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Not found</Typography>
        <Typography variant="h1">404</Typography>
        <Button component={Link} to="/dashboard" variant="text">
          go back
        </Button>
      </Box>
    </Box>
  );
};

export default NoFindPage;
