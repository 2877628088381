import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { Emoji } from "emoji-mart";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";

type Props = DialogProps & {
  messageId: number;
  onCancel: () => void;
};

type ResponseType = {
  [key: string]: {
    first_name: string;
    full_name: string;
    id: number;
    last_name: string;
  }[];
};

type ReactionData = {
  emojiId: string;
  data: {
    first_name: string;
    full_name: string;
    id: number;
    last_name: string;
  }[];
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChatReactionModal: FC<Props> = ({ onCancel, messageId, ...props }) => {
  const [value, setValue] = React.useState(0);
  const [reactionData, setReactionData] = useState<ReactionData[]>([]);
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formatReactionData = (data: ResponseType) => {
    const newData = [];
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const k in data) {
      newData.push({ emojiId: k, data: data[k] });
    }
    setReactionData(newData);
  };

  const getReactions = async () => {
    try {
      const { data } = await api.get(`/messages/${messageId}/emojis`);
      formatReactionData(data);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      onCancel();
    }
  };

  const handleRedirectFromUser = (id: number) => {
    navigate(`/users/${id}`);
    onCancel();
  };

  useEffect(() => {
    getReactions();
  }, []);

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle id="alert-dialog-title">Message Reactions</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {reactionData.map((item, index) => (
                <Tab
                  label={<Emoji emoji={item.emojiId} size={23} />}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
          {reactionData.map((item, index) => (
            <Box
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
            >
              {value === index && (
                <Box
                  sx={{
                    p: 3,
                    overflowY: "auto",
                    height: "300px",
                  }}
                >
                  {item.data.map((el, ind) => (
                    <Paper
                      sx={(theme) => ({
                        height: "40px",
                        mb: "10px",
                        display: "flex",
                        alignItems: "center",
                        pl: "10px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.palette.action.hover,
                        },
                      })}
                      onClick={() => handleRedirectFromUser(el.id)}
                      key={ind}
                    >
                      <Typography variant="body1">{el.full_name}</Typography>
                    </Paper>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatReactionModal;
