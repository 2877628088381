import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { FC } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Field, Form } from "react-final-form";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { ValidationErrors } from "final-form";
import { useApi } from "../../../hooks/useApi";

type Props = DialogProps & {
  handleMutate: () => void;
  onCancel: () => void;
};

type FormType = {
  email: string;
};

const CreateSubscriberDialog: FC<Props> = ({
  onCancel,
  handleMutate,
  ...props
}) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    email: "",
  };

  const validationSchema: Yup.SchemaOf<FormType> = Yup.object().shape({
    email: Yup.string().email().required("Required"),
  });

  const validate = (values: FormType): Promise<ValidationErrors | undefined> =>
    validationSchema
      .validate(values, { abortEarly: false })
      .then(() => undefined)
      .catch((error: Yup.ValidationError) => {
        const errors: { [value: string]: string } = {};
        error.inner.forEach((e) => {
          if (e.path) {
            errors[e.path] = e.message;
          }
        });
        return errors;
      });

  const onSubmit = async (values: FormType, form: any) => {
    try {
      await api.post(`/subscribers`, values);
      form.reset();
      handleMutate();
      onCancel();
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, pristine, touched }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="alert-dialog-title">Create Subscriber</DialogTitle>
            <DialogContent>
              <Box>
                <Field name="email">
                  {({ input, meta }) => (
                    <TextField
                      autoFocus
                      label="Email"
                      fullWidth
                      variant="standard"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                      {...input}
                    />
                  )}
                </Field>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel}>Cancel</Button>
              <Button disabled={pristine || !touched} type="submit">
                Create
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default CreateSubscriberDialog;
