import { OnboardingItem } from "../OnboardingData.types";

export const filterItems = (itemData: OnboardingItem) => {
  if (!!itemData) {
    const asArray = Object.entries(itemData);

    const filtered = asArray.filter(([, value]) => {
      return value === 1;
    });

    const justStrings = Object.fromEntries(filtered);

    return Object.keys(justStrings).map((item) => {
      return `${item},  `;
    });
  }

  return "";
};
