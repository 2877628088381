import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { FC, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = DialogProps & {
  fileName: string;
  onCancel: () => void;
  url: string;
};

const ShowFileModal: FC<Props> = ({ onCancel, fileName, url, ...props }) => {
  const isPdf = url.split(".")[1] === "pdf";
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(pdf: any) {
    setNumPages(pdf.numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <DialogTitle id="alert-dialog-title">File {fileName}</DialogTitle>
      <DialogContent>
        <Box>
          {isPdf ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Document
                file={`${url}`}
                onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
                loading={<>Loading ...</>}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={() => previousPage()}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography variant="body2">
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </Typography>

                <IconButton
                  type="button"
                  disabled={numPages !== null && pageNumber >= numPages}
                  onClick={() => nextPage()}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                img: {
                  maxWidth: "700px",
                  borderRadius: "5px",
                },
              }}
            >
              <TransformWrapper>
                <TransformComponent>
                  <img src={`${url}`} alt={fileName} />
                </TransformComponent>
              </TransformWrapper>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowFileModal;
