import { Fragment, useState } from "react";
import {
  Badge,
  Box,
  IconButton,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import {
  StyledAppBar,
  StyledDrawer,
  StyledDrawerHeader,
  StyledListItemText,
} from "./NavMenu.styled";
import useGlobalCounters from "../../hooks/useGlobalCounters";
import AdminInfo from "./AdminInfo";
import BreadCrumbs from "../BreadCrumbs";
import routes from "./routes";

const NavMenu = () => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const { supportCounter } = useGlobalCounters();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getNavLinkStyle = ({ isActive }: { isActive: boolean }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontWeight: isActive ? "bold" : "normal",
    color: isActive ? theme.palette.primary.dark : "inherit",
    textDecoration: "none",
  });

  return (
    <Box>
      <StyledAppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: "flex", gap: "2rem", alignItems: "center" }}>
            <Typography variant="h6" noWrap>
              Hubmee
            </Typography>
            <Typography variant="body1" noWrap>
              Administration dashboard
            </Typography>
            <BreadCrumbs />
          </Box>
          <Box sx={{ marginLeft: "auto" }}>
            <AdminInfo />
          </Box>
        </Toolbar>
      </StyledAppBar>

      <StyledDrawer variant="permanent" open={open}>
        <StyledDrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </StyledDrawerHeader>
        <Divider />

        <List>
          {routes.map(({ group, items }, index) => (
            <Fragment key={group}>
              {items.map(({ path, label, icon: Icon }) => (
                <ListItem button key={path}>
                  <NavLink to={path} style={getNavLinkStyle}>
                    {({ isActive }) => (
                      <>
                        <ListItemIcon>
                          {path === "/chat_support" ? (
                            <Badge
                              badgeContent={supportCounter}
                              color="error"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <Icon
                                sx={{
                                  "& path": {
                                    fill: isActive
                                      ? theme.palette.primary.dark
                                      : "inherit",
                                  },
                                }}
                              />
                            </Badge>
                          ) : (
                            <Icon
                              sx={{
                                "& path": {
                                  fill: isActive
                                    ? theme.palette.primary.dark
                                    : "inherit",
                                },
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <StyledListItemText primary={label} />
                      </>
                    )}
                  </NavLink>
                </ListItem>
              ))}
              {index < routes.length - 1 && (
                <Divider sx={{ margin: "10px 0" }} />
              )}
            </Fragment>
          ))}
        </List>
      </StyledDrawer>
    </Box>
  );
};

export default NavMenu;
