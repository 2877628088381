import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { FC } from "react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { FormApi } from "final-form";

import { useSnackbar } from "notistack";
import DefaultForm from "../../form/DefaultForm";
import ChatReleaseCreateFields from "../../../pages/ChatRelease/ChatReleaseCreate/ChatReleaseCreateFields";
import EditMessageModalFooter from "./EditMessageModalFooter";
import { useApi } from "../../../hooks/useApi";

type Props = DialogProps & {
  editData: {
    body: string;
    files: any | null;
  };
  messageId: number;
  onCancel: () => void;
  handleReloadMessages: () => void;
};

type EditMessageForm = {
  body: string;
  files: Blob;
};

const EditMessageDialog: FC<Props> = ({
  onCancel,
  editData,
  messageId,
  handleReloadMessages,
  ...props
}) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (values: EditMessageForm, form: FormApi) => {
    try {
      await api.put(`/messages/${messageId}`, { body: values.body });
      form.reset();
      handleReloadMessages();
      onCancel();
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  const initialValue = {
    ...editData,
  };

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DefaultForm<EditMessageForm> data={initialValue} onSubmit={onSubmit}>
        <DialogTitle id="alert-dialog-title">Edit message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ChatReleaseCreateFields isFileDisabled />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EditMessageModalFooter onCancel={onCancel} />
        </DialogActions>
      </DefaultForm>
    </Dialog>
  );
};

export default EditMessageDialog;
