import { Box, TextField } from "@mui/material";
import "emoji-mart/css/emoji-mart.css";
import { Field } from "react-final-form";

const CategoryEditFields = () => {
  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box>
        <Field name="title">
          {({ input }) => (
            <TextField
              {...input}
              id="standard-multiline-flexible"
              label="Category title"
              fullWidth
              multiline
              maxRows={6}
              rows={3}
              sx={{
                marginTop: "20px",
              }}
              variant="outlined"
            />
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default CategoryEditFields;
