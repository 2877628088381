import React, { useEffect } from "react";
import useCommon from "../../hooks/useCommon";

const Statistic = () => {
  const { setBreadCrumbs } = useCommon();
  useEffect(() => {
    setBreadCrumbs?.([{ label: "Dashboard" }]);
  }, [setBreadCrumbs]);

  return <div>Dashboard</div>;
};

export default Statistic;
