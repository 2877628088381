import { PropsWithChildren } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Config } from "final-form";

interface Props<FormValue> {
  data: FormValue;
  onSubmit: (value: FormValue, form: any) => void;
  validate?: Config<FormValue, Partial<FormValue>>["validate"];
  isValidate?: boolean;
}

const DefaultForm = <FormValue,>({
  children,
  data,
  onSubmit,
  validate,
  isValidate = false,
}: PropsWithChildren<Props<FormValue>>) => {
  return (
    <Form
      initialValues={data}
      subscription={{}}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form noValidate={!isValidate} onSubmit={handleSubmit}>
          {children}
        </form>
      )}
    </Form>
  );
};

export default DefaultForm;
