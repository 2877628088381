import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { GlobalOnboardingData } from "../../OnboardingData.types";
import { titleKey } from "../../titleKeyObject.types";
import { filterItems } from "../../functions/filterItems.types";

type Props = {
  data: GlobalOnboardingData;
};

const OnboardingUserData: FC<Props> = ({ data }) => {
  return (
    <Box sx={{ width: "100%" }}>
      {data && (
        <>
          <Typography sx={{ pb: "20px" }} variant="h5">
            User Onboarding
          </Typography>
          {Object.keys(data).map((item, index) => (
            <Box key={index} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px", pr: "12px" }}
                variant="subtitle1"
              >
                {titleKey[item]} :
              </Typography>

              <Typography sx={{ fontSize: "18px" }} variant="subtitle1">
                {filterItems(data[item])}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default OnboardingUserData;
