import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { useApi, useFetcher } from "../../../hooks/useApi";
import DefaultForm from "../../../components/form/DefaultForm";
import UserFields from "./UserFields";
import UserToolBar from "./UserToolBar";
import useCommon from "../../../hooks/useCommon";
import { UserType } from "../Users.type";
import GlobalAnalytic from "../../Analytics/components/GlobalAnalytics";
import OnboardingData from "../../OnboardingData";

const User = () => {
  const { id } = useParams();
  const api = useApi();
  const fetcher = useFetcher();
  const { data, mutate } = useSWR(["/users", id], fetcher);
  const { setBreadCrumbs } = useCommon();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadCrumbs?.([
      { label: "Users", url: "/users" },
      { label: `User #${id}` },
    ]);
  }, [id, setBreadCrumbs]);

  if (!data) return <>Loading ...</>;
  const initialValue = {
    ...data,
  };

  const onSubmit = async (values: UserType) => {
    try {
      const newData = {
        first_name: values.first_name,
        last_name: values.last_name,
        birth_day: values.birth_day,
        gender: values.gender,
      };
      const res = await api.put(`/users/${id}`, newData);
      await mutate(res.data.data);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DefaultForm<UserType> data={initialValue} onSubmit={onSubmit}>
        <UserToolBar userFullName={`${data.first_name} ${data.last_name}`} />
        <UserFields />
      </DefaultForm>
      <Paper sx={{ mt: "15px", p: "24px" }}>
        <GlobalAnalytic id={id ? +id : undefined} title="User Analytic" />
      </Paper>
      <Paper sx={{ mt: "15px", p: "24px" }}>
        <OnboardingData id={id ? +id : undefined} />
      </Paper>
    </Box>
  );
};

export default User;
