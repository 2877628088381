import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import UseCommon from "../../hooks/useCommon";

const BreadCrumbs = () => {
  const { breadCrumbs } = UseCommon();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadCrumbs.map((item) =>
        item.url ? (
          <Link
            key={item.label}
            component={RouterLink as any}
            underline="hover"
            color="inherit"
            to={item.url}
          >
            {item.label}
          </Link>
        ) : (
          <Typography key={item.label} color="text.primary">
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
