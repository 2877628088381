import React, { FC } from "react";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { capitalize } from "lodash";
import moment from "moment/moment";
import { FeedbackUserTypes } from "./UserFeedback.type";

type UserFeedbackRowProps = {
  data: FeedbackUserTypes;
};

const UserFeedbackRow: FC<UserFeedbackRowProps> = ({ data }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.user_id ? data.user_id : "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.created_at
            ? moment.utc(data.created_at).local().format("LLL")
            : "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.type ? capitalize(data.type) : "-"}
        </Typography>
      </TableCell>

      <TableCell component="th" scope="row">
        <Typography variant="body2">
          <Box
            sx={{
              maxWidth: "400px",
              minWidth: "100px",
              maxHeight: "80px",
              overflow: "hidden",
              overflowY: "scroll",
              wordBreak: "break-all",
            }}
          >
            {data.data?.length ? data.data.join(", ") : ""}
          </Box>
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default UserFeedbackRow;
