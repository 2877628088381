import { FC } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useModal } from "mui-modal-provider";
import { HelpCenterTypes } from "./HelpCenter.type";
import ConfirmModal from "../../components/modals/ConfirmModal";
import ShowFileModal from "../../components/modals/ShowFileModal";

type UserFeedbackRowProps = {
  data: HelpCenterTypes;
  handleDeleteFeedback: (id: number) => void;
};

const HelpCenterRow: FC<UserFeedbackRowProps> = ({
  data,
  handleDeleteFeedback,
}) => {
  const { showModal } = useModal();

  const handleShowModal = (media: any) => {
    const modal = showModal(ShowFileModal, {
      fileName: media.original_filename,
      url: media.additional_info.pdf_url
        ? media.additional_info.pdf_url
        : media.url,
      onCancel: () => {
        modal.hide();
      },
    });
  };
  const handleOpenConfirmationDialog = (id: number) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete feedback ?`,
      body: `Are you sure you want to delete this feedback?`,
      onConfirm: () => {
        handleDeleteFeedback(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const reformatType = (type: string) => {
    switch (type) {
      case "suggestion":
        return "Make a suggestion";

      case "report_issue":
        return "Report an issue";

      case "support":
        return "Send us an email";
      default:
        return type;
    }
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>

      <TableCell component="th" scope="row">
        <Typography variant="body2">{reformatType(data.type)}</Typography>
      </TableCell>

      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.email}</Typography>
      </TableCell>

      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxWidth: "150px",
            minWidth: "100px",
            maxHeight: "80px",
            overflowY: "auto",
            wordBreak: "break-all",
          }}
        >
          <Typography variant="body2">
            {data.full_name ? data.full_name : "-"}
          </Typography>
        </Box>
      </TableCell>

      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxWidth: "500px",
            minWidth: "200px",
            maxHeight: "80px",
            overflowY: "auto",
            wordBreak: "break-all",
          }}
        >
          <Typography variant="body2">
            {data.message ? data.message : "-"}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="column">
        <Box
          sx={{
            maxWidth: "100px",
            minWidth: "200px",
            maxHeight: "80px",
            display: "flex",
            flexWrap: "wrap",
            overflowY: "auto",
          }}
        >
          {data.documents !== null &&
            data.documents.map((media: { original_filename: string }) => (
              <Tooltip title={media?.original_filename}>
                <Box
                  onClick={() => handleShowModal(media)}
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "50px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <InsertDriveFileIcon />
                  </Box>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      overflow: "hidden",
                    }}
                  >
                    {media?.original_filename}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
        </Box>
      </TableCell>
      <TableCell size="small" align="center" component="th" scope="row">
        <Box>
          <IconButton size="small">
            <DeleteIcon onClick={() => handleOpenConfirmationDialog(data.id)} />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default HelpCenterRow;
