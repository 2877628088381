import { useCallback, useEffect, useState } from "react";
import type { ChangeEvent } from "react";
import {
  Box,
  Card,
  Fade,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import useSWR from "swr";
import ClearIcon from "@mui/icons-material/Clear";
import { useDebouncedCallback } from "use-debounce";
import { useSnackbar } from "notistack";
import HelpCenterRow from "./HelpCenterRow";
import { useApi, useFetcher } from "../../hooks/useApi";
import { HelpCenterTypes } from "./HelpCenter.type";
import useCommon from "../../hooks/useCommon";

const HelpCenter = () => {
  const fetcher = useFetcher();
  const api = useApi();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState<"desc" | "asc">("desc");
  const [sortBy, setSorBy] = useState("");
  const [search, setSearch] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const { setBreadCrumbs } = useCommon();
  const { enqueueSnackbar } = useSnackbar();

  const replaceUndefinedOrNull = (key: string, value: any) => {
    if (value === null || value === undefined || value === "") {
      return undefined;
    }
    return value;
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Help center" }]);
  }, [setBreadCrumbs]);

  const searchParams = useCallback(() => {
    return JSON.parse(
      JSON.stringify(
        {
          per_page: perPage,
          order_by: orderBy.toLocaleUpperCase(),
          page: page + 1,
          sort_by: sortBy,
          search,
          start_date: startDay,
          end_date: endDay,
        },
        replaceUndefinedOrNull
      )
    );
  }, [perPage, orderBy, page, sortBy, search, startDay, endDay]);

  const { data, mutate } = useSWR(
    ["/help-center/feedback", searchParams()],
    fetcher
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (property: string) => () => {
    const isAscending = sortBy === property && orderBy === "asc";
    setSorBy(property);
    setOrderBy(isAscending ? "desc" : "asc");
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = useDebouncedCallback((value) => {
    setSearch(value);
    setSorBy("");
    setOrderBy("desc");
  }, 1000);

  const clearSearchParams = () => {
    setSearch("");
    setStartDay("");
    setEndDay("");
  };

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/help-center/feedback/${id}`);
      await mutate();
      enqueueSnackbar("HelpCenter deleted", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const skeletonArray = Array(perPage).fill(Array(7).fill(""));

  return (
    <Box sx={{ width: "100%" }}>
      <Card>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                margin: "20px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  sx={{ width: 220 }}
                  id="type_id"
                  placeholder="Search..."
                  label="Search"
                  variant="standard"
                  onChange={(e) => handleChangeSearch(e.target.value)}
                  fullWidth
                />
                <TextField
                  id="date"
                  label="Start date"
                  type="date"
                  value={startDay}
                  variant="standard"
                  onChange={(e) => setStartDay(e.target.value)}
                  sx={{ width: 150, marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="End date"
                  variant="standard"
                  type="date"
                  value={endDay}
                  onChange={(e) => setEndDay(e.target.value)}
                  sx={{ width: 150, marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Fade in={!!search || !!startDay || !!endDay}>
                  <Box sx={{ marginLeft: "20px" }}>
                    <IconButton onClick={clearSearchParams} size="small">
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Fade>
              </Box>
            </Box>
          </Box>
        </Toolbar>

        <TableContainer>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("id")}
                    active={sortBy === "id"}
                    direction={sortBy === "id" ? orderBy : "asc"}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>

                <TableCell>Type</TableCell>

                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("email")}
                    active={sortBy === "email"}
                    direction={sortBy === "email" ? orderBy : "asc"}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>

                <TableCell>Full name</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Documents</TableCell>
                <TableCell size="small" align="right" />
              </TableRow>
            </TableHead>

            {data ? (
              <TableBody>
                {data.data.map((item: HelpCenterTypes) => (
                  <HelpCenterRow
                    key={item.id}
                    data={item}
                    handleDeleteFeedback={handleDelete}
                  />
                ))}
              </TableBody>
            ) : (
              skeletonArray.map((item, index: number) => (
                <TableRow key={index}>
                  {item.map((el: string[], ind: number) => (
                    <TableCell key={ind} component="th" scope="row">
                      <Skeleton animation="wave" variant="text" height="30px" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data?.meta?.total || 0}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

export default HelpCenter;
