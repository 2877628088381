import React, { FC } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "mui-modal-provider";

import ConfirmModal from "../../components/modals/ConfirmModal";
import { Subscriber } from "./Subscribers.type";

type SubscribersRowProps = {
  data: Subscriber;
  handleDeleteUser: (id: number) => void;
};

const SubscribersRow: FC<SubscribersRowProps> = ({
  data,
  handleDeleteUser,
}) => {
  const { showModal } = useModal();

  const handleOpenConfirmationDialog = (id: number, email: string) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete Subscriber ?`,
      body: `Are you sure you want to delete ${email}?`,
      onConfirm: () => {
        handleDeleteUser(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.email}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.created_at}</Typography>
      </TableCell>
      <TableCell size="small" align="center" component="th" scope="row">
        <Box>
          <IconButton
            onClick={() => handleOpenConfirmationDialog(data.id, data.email)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default SubscribersRow;
