import React, { useContext, useState } from "react";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Form, Field } from "react-final-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";

import { useNavigate } from "react-router-dom";
import { useApi } from "../../hooks/useApi";

import { CommonContext } from "../../context/CommonContext";

const Auth = () => {
  const theme = useTheme();

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAuth, setToken } = useContext(CommonContext);

  const onSubmit = async (data: { login: string; password: string }) => {
    try {
      setLoading(true);
      const res = await api.post("/login", data);
      localStorage.setItem("dashboard-token", res.data.token);
      setAuth?.(true);
      setToken?.(res.data.token);
      navigate("/dashboard");

      enqueueSnackbar("Login successful", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Something want wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "300px",
        p: "15px",
        borderRadius: "5px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  color: theme.palette.primary.main,
                }}
                variant="h4"
              >
                Hubmee Dashbord
              </Typography>
            </Box>
            <Box>
              <Field
                name="login"
                render={({ input }) => (
                  <TextField
                    {...input}
                    sx={{ marginTop: "20px" }}
                    fullWidth
                    label="Login"
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Field
                name="password"
                render={({ input }) => (
                  <TextField
                    {...input}
                    sx={{ marginTop: "20px" }}
                    fullWidth
                    type="password"
                    label="Password"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoadingButton loading={loading} variant="outlined" type="submit">
                Login
              </LoadingButton>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default Auth;
