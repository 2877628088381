import { set } from "lodash";

export const errorService = (data) => {
  const newError = {};
  const reg = /\d/;
  const keys = Object.keys(data);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const newKeys = keys[i].split(".");
    let route = "";

    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < newKeys.length; j++) {
      if (reg.test(newKeys[j])) {
        route += `[${newKeys[j]}]`;
      } else {
        route += `${j === 0 ? "" : "."}${newKeys[j]}`;
      }
    }
    set(newError, route, data[keys[i]][0]);
  }

  return newError;
};
