import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import Auth from "./pages/Auth";
import Dashboard from "./components/Dashbord/Dashboard";

import { CommonContext } from "./context/CommonContext";
import socketConnect from "./service/Websocket/socketConnect";

const App = () => {
  const { isAuth } = useContext(CommonContext);
  const { setAuth, setToken } = useContext(CommonContext);

  useEffect(() => {
    if (localStorage.getItem("dashboard-token")) {
      setAuth?.(true);
      setToken?.(localStorage.getItem("dashboard-token") || "");
      socketConnect.setAuthorizationToken(
        localStorage.getItem("dashboard-token")
      );
    }
    return () => {
      socketConnect.setAuthorizationToken("");
      socketConnect.disconnect();
    };
  }, [setAuth, setToken]);

  return <Box>{isAuth ? <Dashboard /> : <Auth />}</Box>;
};

export default App;
