import { FC } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  MuiBaseAutocompleteTextFiledProps,
  Options,
} from "./MuiBaseAutocompleteTextFiled.types";

const MuiBaseAutocompleteTextFiled: FC<MuiBaseAutocompleteTextFiledProps> = ({
  label,
  options,
  input,
  customOnChangeFunc,
  isRequired = false,
}) => {
  return (
    <Autocomplete
      value={options?.find((option) => option.value === input.value) || null}
      onChange={(event, newValue: Options | null) => {
        input.onChange(newValue ? newValue.value : null);
        if (!!customOnChangeFunc) {
          customOnChangeFunc(newValue ? +newValue.value : null);
        }
      }}
      inputValue={
        options?.find((option) => option.value === input.value)?.label || ""
      }
      onInputChange={(event, newInputValue) => {
        const selectedOption = options.find(
          (option) => option.label === newInputValue
        );
        input.onChange(selectedOption ? selectedOption.value : newInputValue);
      }}
      id="MuiBaseAutocompleteTextFiled"
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, selectedValue) =>
        option.value === selectedValue?.value
      }
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label={label} required={isRequired} />
      )}
    />
  );
};

export default MuiBaseAutocompleteTextFiled;
