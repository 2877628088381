import { Backdrop, Card, CircularProgress, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormApi } from "final-form";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import DefaultForm from "../../../components/form/DefaultForm";
import ChatReleaseCreateFields from "./ChatReleaseCreateFields";
import ChatReleaseCreateBar from "./ChatReleaseCreateBar";
import { useApi } from "../../../hooks/useApi";
import useCommon from "../../../hooks/useCommon";

type ChatReleaseCreateForm = {
  body: string;
  files: Blob[];
};

const ChatReleaseCreate = () => {
  const api = useApi();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();
  const { setBreadCrumbs } = useCommon();
  const initialValue = {
    body: "",
    files: [],
  };

  useEffect(() => {
    if (!!Number.parseInt(id || "", 10) === false) {
      enqueueSnackbar("Chat id not set", { variant: "error" });
      navigate("/chat_update");
    }
    setBreadCrumbs?.([
      { label: "Release chat", url: "/chat_update" },
      { label: "Create release message" },
    ]);
  }, []);

  const onSubmit = async (values: ChatReleaseCreateForm, form: FormApi) => {
    try {
      setLoading(true);

      const formData = new FormData();
      if (values.body.trim() !== "") {
        formData.append("body", values.body);
      }

      if (!!values.files.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < values.files.length; i++) {
          formData.append("files[]", values.files[i]);
        }
      }

      await api.post(`/threads/${id}/messages`, formData);
      form.reset();
      navigate("/chat_update");
      setLoading(false);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <Card sx={{ width: "100%" }}>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: () => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <DefaultForm<ChatReleaseCreateForm>
        data={initialValue}
        onSubmit={onSubmit}
      >
        <ChatReleaseCreateBar />
        <ChatReleaseCreateFields />
      </DefaultForm>
    </Card>
  );
};

export default ChatReleaseCreate;
