import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  Fade,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Tab,
  TextField,
  Toolbar,
  useTheme,
} from "@mui/material";
import useSWR from "swr";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";

import UserFeedbackRow from "./UserFeedbackRow";
import { useFetcher } from "../../hooks/useApi";
import { FeedbackUserTypes } from "./UserFeedback.type";
import useCommon from "../../hooks/useCommon";
import { AnalyticTimePreset } from "../Analytics/analytic.enum";
import { timeSelectConfig } from "../Analytics/analytic.config";

const CancelFeedback = () => {
  const fetcher = useFetcher();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState<"desc" | "asc">("desc");
  const [sortBy, setSorBy] = useState("");
  const [search, setSearch] = useState("");
  const [startDay, setStartDay] = useState<any>("");
  const [endDay, setEndDay] = useState<any>("");
  const { setBreadCrumbs } = useCommon();
  const [datePreset, setDatePreset] = useState(AnalyticTimePreset.allTime);
  const [activeTab, setActiveTab] = useState("subscription_cancel");
  const theme = useTheme();

  const replaceUndefinedOrNull = (key: string, value: any) => {
    if (value === null || value === undefined || value === "") {
      return undefined;
    }
    return value;
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Cancel Feedback" }]);
  }, [setBreadCrumbs]);

  useMemo(() => {
    if (datePreset === AnalyticTimePreset.allTime) {
      setEndDay("");
      setStartDay("");
    }
    if (datePreset === AnalyticTimePreset.today) {
      setStartDay(moment());
      setEndDay(moment());
    }
    if (datePreset === AnalyticTimePreset.yesterday) {
      setStartDay(moment().subtract(1, "day"));
      setEndDay(moment().subtract(1, "day"));
    }
    if (datePreset === AnalyticTimePreset.week) {
      setStartDay(moment().subtract(1, "week"));
      setEndDay(moment());
    }
    if (datePreset === AnalyticTimePreset.month) {
      setStartDay(moment().subtract(1, "month"));
      setEndDay(moment());
    }
  }, [datePreset]);

  const searchParams = useCallback(() => {
    return JSON.parse(
      JSON.stringify(
        {
          per_page: perPage,
          order_by: orderBy.toLocaleUpperCase(),
          page: page + 1,
          sort_by: sortBy,
          search,
          date_time_from: startDay
            ? moment(startDay)
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : "",
          date_time_to: endDay
            ? moment(endDay).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
            : "",
        },
        replaceUndefinedOrNull
      )
    );
  }, [perPage, orderBy, page, sortBy, search, startDay, endDay]);

  const { data } = useSWR(
    [`/cancel-feedback/${activeTab}`, searchParams()],
    fetcher
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (property: string) => () => {
    const isAscending = sortBy === property && orderBy === "asc";
    setSorBy(property);
    setOrderBy(isAscending ? "desc" : "asc");
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearSearchParams = () => {
    setSearch("");
    setStartDay("");
    setEndDay("");
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const skeletonArray = Array(perPage).fill(Array(9).fill(""));

  return (
    <Box sx={{ width: "100%" }}>
      <Card>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Subscription Cancel" value="subscription_cancel" />
          <Tab label="Delete Account" value="delete_account" />
          <Tab
            label="Delete Account Registration Step"
            value="delete_account_registration_step"
          />
        </Tabs>

        <Toolbar>
          <Box
            sx={{
              width: "100%",
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                margin: "20px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "200px", mr: "20px", pt: "10px" }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="standard"
                      value={datePreset}
                      label="Date preset"
                      onChange={(e) => {
                        setDatePreset(e.target.value as AnalyticTimePreset);
                      }}
                    >
                      {timeSelectConfig.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  id="date"
                  label="Start date"
                  type="date"
                  value={startDay ? moment(startDay).format("YYYY-MM-DD") : ""}
                  variant="standard"
                  onChange={(e) => setStartDay(e.target.value)}
                  sx={{ width: 150, marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="End date"
                  variant="standard"
                  type="date"
                  value={endDay ? moment(endDay).format("YYYY-MM-DD") : ""}
                  onChange={(e) => setEndDay(e.target.value)}
                  sx={{ width: 150, marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Fade in={!!search || !!startDay || !!endDay}>
                  <Box sx={{ marginLeft: "20px" }}>
                    <IconButton onClick={clearSearchParams} size="small">
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Fade>
              </Box>
            </Box>
          </Box>
        </Toolbar>

        <TableContainer>
          <Table
            aria-label="simple table"
            size="small"
            sx={{
              border: `1px solid rgba(224, 224, 224, 1)`,
              borderRadius: "5px",
              marginBottom: "25px",
            }}
          >
            <TableHead>
              <TableRow sx={{ background: theme.palette.action.hover }}>
                <TableCell>Id</TableCell>
                <TableCell>User id</TableCell>
                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("created_at")}
                    active={sortBy === "created_at"}
                    direction={sortBy === "created_at" ? orderBy : "asc"}
                  >
                    Created at
                  </TableSortLabel>
                </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>

            {data ? (
              <TableBody>
                {data.data.map((item: FeedbackUserTypes) => (
                  <UserFeedbackRow key={item.id} data={item} />
                ))}
              </TableBody>
            ) : (
              skeletonArray.map((item, index: number) => (
                <TableRow key={index}>
                  {item.map((el: string[], ind: number) => (
                    <TableCell key={ind} component="th" scope="row">
                      <Skeleton animation="wave" variant="text" height="30px" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data?.total || 0}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

export default CancelFeedback;
