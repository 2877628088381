import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { head, tail } from "lodash";

let axiosInstance: AxiosInstance | null = null;

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_HOST,
};

export const useApi = () => {
  const accessToken = localStorage.getItem("dashboard-token");

  if (!axiosInstance) {
    axiosInstance = axios.create(defaultConfig);
  }

  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // eslint-disable-next-line no-console
    console.warn("[REST API Warning]: the is no session available!");
  }

  return axiosInstance;
};

export const useFetcher = () => {
  const axiosInst = useApi();

  return async (...args: string[]) => {
    const resultUrl: string = tail(args).reduce((url: string, data: any) => {
      let link = url;

      if (typeof data === "object") {
        link += `?${new URLSearchParams(data)}`;
      } else {
        link += `/${data}`;
      }
      return link;
    }, head(args) ?? "");

    const r = await axiosInst.get(resultUrl);

    return r.data;
  };
};
