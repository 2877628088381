import { FC } from "react";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Category, Tags } from "../../Post.types";

type PostRowItemProps = {
  title?: string;
  items?: Category[] | Tags[];
  handleOpenEditDialog: (
    title: string,
    id: number | null,
    categoryId?: number | null
  ) => void;
  handleOpenConfirmationDialog: (id: number, title: string) => void;
  isCategories?: boolean;
};

const PostRowItem: FC<PostRowItemProps> = ({
  title = "",
  items,
  handleOpenConfirmationDialog,
  handleOpenEditDialog,
  isCategories,
}) => {
  return (
    <Box
      sx={{
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          margin: "10px 20px 0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {title}
      </Box>
      {items?.map((i: any) => {
        return (
          <Box
            key={i.id}
            sx={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "dotted 1px black",
              borderRadius: "70px",
              marginTop: "10px",
            }}
          >
            <Box sx={{ margin: "0 5px" }}>{isCategories ? i.title : i.tag}</Box>
            <Box>
              <IconButton size="small">
                <EditIcon
                  onClick={() =>
                    handleOpenEditDialog(
                      isCategories ? i.title : i.tag,
                      i.id,
                      isCategories ? undefined : i.category?.id
                    )
                  }
                />
              </IconButton>
              <IconButton
                onClick={() =>
                  handleOpenConfirmationDialog(
                    i.id,
                    isCategories ? i.title : i.tag
                  )
                }
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          marginTop: "10px",
        }}
        onClick={() => handleOpenEditDialog("", null)}
      >
        <AddIcon
          sx={{
            color: "#5DCB42",
          }}
        />
      </Box>
    </Box>
  );
};

export default PostRowItem;
