import React, { FC } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useModal } from "mui-modal-provider";
import { FeedbackUserTypes } from "./UserFeedback.type";
import ConfirmModal from "../../components/modals/ConfirmModal";
import ShowFileModal from "../../components/modals/ShowFileModal";

type UserFeedbackRowProps = {
  data: FeedbackUserTypes;
  handleDeleteFeedback: (id: number) => void;
};

const UserFeedbackRow: FC<UserFeedbackRowProps> = ({
  data,
  handleDeleteFeedback,
}) => {
  const { showModal } = useModal();

  const handleShowModal = (media: any) => {
    const modal = showModal(ShowFileModal, {
      fileName: media.original_filename,
      url: media.additional_info.pdf_url
        ? media.additional_info.pdf_url
        : media.url,
      onCancel: () => {
        modal.hide();
      },
    });
  };
  const handleOpenConfirmationDialog = (id: number) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete feedback ?`,
      body: `Are you sure you want to delete this feedback?`,
      onConfirm: () => {
        handleDeleteFeedback(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.email ? data.email : "-"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.phone ? data.phone : "-"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.created_at}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.design}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.functionality}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.idea}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          <Box
            sx={{
              maxWidth: "400px",
              minWidth: "100px",
              maxHeight: "80px",
              overflow: "hidden",
              overflowY: "scroll",
              wordBreak: "break-all",
            }}
          >
            {data.message}
          </Box>
        </Typography>
      </TableCell>
      <TableCell component="th" scope="column">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.documents !== null &&
            data.documents.map((media: { original_filename: string }) => (
              <Tooltip title={media?.original_filename}>
                <Box
                  onClick={() => handleShowModal(media)}
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "50px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <InsertDriveFileIcon />
                  </Box>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      overflow: "hidden",
                    }}
                  >
                    {media?.original_filename}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
        </Box>
      </TableCell>
      <TableCell size="small" align="center" component="th" scope="row">
        <Box>
          <IconButton size="small">
            <DeleteIcon onClick={() => handleOpenConfirmationDialog(data.id)} />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UserFeedbackRow;
