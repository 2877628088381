import React, { memo, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useApi } from "../../hooks/useApi";
import useCommon from "../../hooks/useCommon";
import useGlobalCounters from "../../hooks/useGlobalCounters";
import SocketConnect from "../../service/Websocket/socketConnect";
import { ThreadItemType } from "../../pages/SupportChat/SupportChatType";
import useChat from "../../hooks/useChat";

const GlobalSockets = () => {
  const api = useApi();
  const { profile } = useCommon();
  const { setSupportCounter } = useGlobalCounters();
  const { addThread, sortThreads, setThreadCounter } = useChat();
  const { enqueueSnackbar } = useSnackbar();

  const getGlobalCounters = async () => {
    try {
      const { data } = await api.get("/admin/global-counters");
      setSupportCounter?.(data.count_unread_message);
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const getThreadCounter = async (id: number) => {
    try {
      const { data } = await api.get(`/threads/${id}/counter`);
      setThreadCounter(id, data.count_unread_messages);
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  // TODO fix sockets

  const seenMessages = async (id: number) => {
    try {
      await api.put(`/threads/${id}/seen`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const messageCreatedAction = (e: { thread: ThreadItemType }) => {
    if (
      window.location.href.includes(`/chat_support/${e.thread.id}`) === false
    ) {
      if (window.location.href.includes("/chat_support")) {
        getThreadCounter(e.thread.id);
      }
      getGlobalCounters();
    } else {
      seenMessages(e.thread.id);
    }
    sortThreads(e.thread.id);
  };

  const getThread = async (id: number) => {
    try {
      const { data } = await api.get(`/threads/${id}`);
      addThread(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    const userChannel = SocketConnect.connect.private(`users.${profile?.id}`);
    SocketConnect.setChannel(`users.${profile?.id}`, userChannel);
    userChannel
      .listen(".threads.counter", () => {})
      .listen(".thread.created", (e: { thread_id: number }) => {
        getThread(e.thread_id);
      })
      .listen(".message.created", (e: { thread: ThreadItemType }) => {
        messageCreatedAction(e);
      });

    return () => {
      SocketConnect.connect.leave(`private-thread.${profile?.id}`);
      SocketConnect.removeChannel(`private-thread.${profile?.id}`);
    };
  }, []);

  return <></>;
};

export default memo(GlobalSockets);
