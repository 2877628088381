import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

type StubProps = {
  text: string;
};

const Stub: FC<StubProps> = ({ text }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};

export default Stub;
