import { Navigate, useLocation } from "react-router-dom";
import React, { FC, Suspense, useContext } from "react";
import { CommonContext } from "../../../context/CommonContext";

type PrivateRouteProps = {
  children: JSX.Element;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { isAuth } = useContext(CommonContext);
  const location = useLocation();
  const url = new URLSearchParams();
  url.set("redirect", location.pathname + location.search);

  return isAuth ? (
    <Suspense fallback={<>...</>}>{children}</Suspense>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        search: url.toString(),
      }}
    />
  );
};

export default PrivateRoute;
