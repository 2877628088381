import Dialog, { DialogProps } from "@mui/material/Dialog";
import { FC } from "react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { FormApi } from "final-form";

import { useSnackbar } from "notistack";
import DefaultForm from "../../form/DefaultForm";
import EditCategoryModalFooter from "./EditCategoryModalFooter";
import { useApi } from "../../../hooks/useApi";
import CategoryEditFields from "./components/CategoryEditFields";
import { Tags } from "../../../pages/Posts/Post.types";

type EditCategoryModalProps = DialogProps & {
  editData: {
    title: string;
  };
  allTags: Tags[];
  tagId: number | null;
  onCancel: () => void;
  onUpdate: () => void;
};

type EditCategoryTitleForm = {
  title: string;
};

const EditCategoryModal: FC<EditCategoryModalProps> = ({
  onCancel,
  editData,
  tagId,
  onUpdate,
  allTags,
  ...props
}) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (values: EditCategoryTitleForm, form: FormApi) => {
    if (tagId === null) {
      try {
        await api.post(`/blog/categories`, values);
        form.reset();
        onCancel();
        onUpdate();
        enqueueSnackbar("Category successful created", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } else {
      try {
        await api.put(`/blog/categories/${tagId}`, values);
        form.reset();
        onCancel();
        onUpdate();
        enqueueSnackbar("Category successful updated", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    }
  };
  const initialValue = {
    ...editData,
  };

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DefaultForm<EditCategoryTitleForm>
        data={initialValue}
        onSubmit={onSubmit}
      >
        <DialogTitle id="alert-dialog-title">
          {tagId === null ? "Create new category" : "Edit category"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CategoryEditFields />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EditCategoryModalFooter onCancel={onCancel} />
        </DialogActions>
      </DefaultForm>
    </Dialog>
  );
};

export default EditCategoryModal;
