import { createContext } from "react";

interface ICounterContext {
  supportCounter: number;
  setSupportCounter?: (count: number) => void;
}

export const CounterContext = createContext<ICounterContext>({
  supportCounter: 0,
});
