import React, { FC, useCallback, useMemo, useState } from "react";
import { CounterContext } from "../../context/CountersContext/CounterContext";

type CounterProviderProps = {
  children: React.ReactNode;
};

const CountersProvider: FC<CounterProviderProps> = ({ children }) => {
  const [supportCounterData, setSupportCounterData] = useState<number>(0);

  const setSupportCounter = useCallback((count: number) => {
    setSupportCounterData(count);
  }, []);

  const contextValue = useMemo(
    () => ({
      supportCounter: supportCounterData,
      setSupportCounter,
    }),
    [setSupportCounter, supportCounterData]
  );

  return (
    <CounterContext.Provider value={contextValue}>
      {children}
    </CounterContext.Provider>
  );
};

export default CountersProvider;
