import { AnalyticTimePreset } from "./analytic.enum";

export const timeSelectConfig = [
  {
    value: AnalyticTimePreset.allTime,
    label: "All time",
  },
  {
    value: AnalyticTimePreset.today,
    label: "Today",
  },
  {
    value: AnalyticTimePreset.yesterday,
    label: "Yesterday",
  },
  {
    value: AnalyticTimePreset.week,
    label: "The last 7 days",
  },
  {
    value: AnalyticTimePreset.month,
    label: "Last month",
  },
];
