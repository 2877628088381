import React, { useState } from "react";
import {
  Box,
  Card,
  ClickAwayListener,
  IconButton,
  Input,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import CloseIcon from "@mui/icons-material/Close";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Field, FormSpy } from "react-final-form";

const ChatReleaseCreateFields = ({ isFileDisabled = false }) => {
  const [showEmoji, setShowEmoji] = useState(false);

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box sx={{ position: "relative" }}>
        <Field name="body">
          {({ input }) => (
            <TextField
              {...input}
              id="standard-multiline-flexible"
              label="Message"
              fullWidth
              multiline
              maxRows={6}
              rows={3}
              sx={{
                marginTop: "20px",
              }}
              variant="outlined"
            />
          )}
        </Field>

        <Box sx={{ display: "flex" }}>
          <Box>
            <IconButton onClick={() => setShowEmoji(!showEmoji)}>
              <InsertEmoticonIcon />
            </IconButton>

            {showEmoji && (
              <ClickAwayListener onClickAway={() => setShowEmoji(false)}>
                <Card>
                  <FormSpy>
                    {({ form }) => (
                      <Picker
                        style={{
                          position: "fixed",
                        }}
                        showSkinTones={false}
                        onSelect={(event) => {
                          if ("native" in event) {
                            form.change(
                              "body",
                              (form.getState().values.body || "") + event.native
                            );
                          }
                        }}
                      />
                    )}
                  </FormSpy>
                </Card>
              </ClickAwayListener>
            )}
          </Box>
          <Box>
            <FormSpy>
              {({ form }) => (
                <label htmlFor="icon-button-file">
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const fileList = e.target.files;
                      if (!fileList) return;
                      form.mutators.push("files", fileList[0]);
                    }}
                    sx={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                  />

                  <IconButton component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>
              )}
            </FormSpy>
          </Box>
        </Box>
        <Box sx={{ margin: "20px 0" }}>
          <FormSpy>
            {({ form }) => (
              <Box>
                {form.getState().values.files.map((el: any, index: number) => (
                  <Paper
                    sx={{
                      height: "40px",
                      maxWidth: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                    key={index}
                  >
                    <Typography
                      sx={{
                        paddingLeft: "5px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "180px",
                        overflow: "hidden",
                      }}
                      variant="body1"
                    >
                      {el.name || el.original_filename}
                    </Typography>
                    {isFileDisabled ? (
                      <Tooltip title="You cant edit files">
                        <IconButton
                          disabled
                          onClick={() => form.mutators.remove("files", index)}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        onClick={() => form.mutators.remove("files", index)}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Paper>
                ))}
              </Box>
            )}
          </FormSpy>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatReleaseCreateFields;
