import React from "react";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import { FormSpy } from "react-final-form";

const ChatReleaseCreateBar = () => {
  return (
    <Toolbar>
      <Box
        sx={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="span">
          Create the Message
        </Typography>
      </Box>

      <FormSpy
        subscription={{
          dirty: true,
          pristine: true,
          touched: true,
        }}
      >
        {({ pristine, touched, form }) => (
          <>
            <Button
              sx={{ marginLeft: "auto", marginRight: "1rem" }}
              color="primary"
              onClick={() => form.reset()}
            >
              Reset
            </Button>
            <Button
              type="submit"
              disabled={pristine || !touched}
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </>
        )}
      </FormSpy>
    </Toolbar>
  );
};

export default ChatReleaseCreateBar;
