import { Box, Card, CircularProgress } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { groupBy } from "lodash";
import { useApi } from "../../../hooks/useApi";
import useChat from "../../../hooks/useChat";
import ChatHeader from "./ChatHeader";
import MessageContainer from "./Messages/MessageContainer";
import ChatFooter from "./ChatFooter/ChatFooter";
import { MessageType } from "../SupportChatType";
import SocketConnect from "../../../service/Websocket/socketConnect";
import useGlobalCounters from "../../../hooks/useGlobalCounters";

const ChatContainer = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const {
    setSelectedThreadState,
    selectedThread,
    setMessagesState,
    messages,
    addMessage,
    resetMessages,
    resetSelectedThread,
    setSeenMessage,
    setThreadCounter,
    sortThreads,
  } = useChat();
  const { setSupportCounter } = useGlobalCounters();

  const ref = useRef<HTMLDivElement>(null);
  const [isStopPagination, setIsStopPagination] = useState(false);
  const [lastId, setLastId] = useState(0);
  const [showStub, setShowStub] = useState(false);

  const handleScrollToBottom = () => {
    const node = ref.current;
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getThread = useCallback(async () => {
    try {
      const { data } = await api.get(`/threads/${id}`);
      setSelectedThreadState(data);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  }, [api, enqueueSnackbar, id, setSelectedThreadState]);

  const getMessages = useCallback(
    async (lastMessageId: number) => {
      try {
        setShowStub(false);
        const { data } = await api.get(
          `/threads/${id}/messages?id=${
            !!lastMessageId ? lastMessageId : ""
          }&&direction=1`
        );

        if (lastMessageId !== 0) {
          setMessagesState(data.data);
          setLastId(data.data[0].id);
        } else {
          setMessagesState(data.data);
          handleScrollToBottom();
        }
        if (data.data.length < 15) {
          setIsStopPagination(true);
        }

        if (lastMessageId === 0 && data.data.length === 0) {
          setShowStub(true);
        }
      } catch (e) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    },
    [api, enqueueSnackbar, id, setMessagesState, lastId]
  );

  const handleAddMessage = (messageItem: MessageType) => {
    addMessage(messageItem, false);
    if (id) {
      sortThreads(+id);
    }
    setShowStub(false);
  };

  const getGlobalCounters = async () => {
    try {
      const { data } = await api.get("/admin/global-counters");
      setSupportCounter?.(data.count_unread_message);
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useEffect(() => {
    if (!id) return;
    Promise.all([])
      .then(() => getThread())
      .then(() => getMessages(0))
      .then(() => handleScrollToBottom())
      .then(() => {
        setThreadCounter(+id, 0);
        getGlobalCounters();
      });

    const messagesChannel = SocketConnect.connect.private(`thread.${id}`);
    SocketConnect.setChannel(`thread.${id}`, messagesChannel);
    messagesChannel
      .listen(".message.created", (e: { message: MessageType }) => {
        addMessage(e.message, true);
        handleScrollToBottom();
      })
      .listen(".seen.messages", () => {
        setSeenMessage();
      });

    return () => {
      SocketConnect.connect.leave(`private-thread.${id}`);
      SocketConnect.removeChannel(`private-thread.${id}`);
      resetMessages();
      resetSelectedThread();
    };
  }, [id]);

  const users = useMemo(() => {
    if (selectedThread)
      return groupBy(selectedThread.all_users, (item) => item.id);
    return null;
  }, [selectedThread]);

  if (!selectedThread)
    return (
      <Box
        sx={{
          width: "70%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Card sx={{ width: "70%", display: "flex", flexDirection: "column" }}>
      <ChatHeader data={selectedThread} />
      {users !== null && (
        <MessageContainer
          ref={ref}
          isActive={selectedThread.is_active}
          showStub={showStub}
          users={users}
          messageData={messages}
          lastId={lastId}
          isStopPagination={isStopPagination}
          setLastId={(lastMessageId) => {
            getMessages(lastMessageId);
          }}
        />
      )}

      {selectedThread.is_active && (
        <ChatFooter
          handleScrollToBottom={handleScrollToBottom}
          handleAddMessage={handleAddMessage}
        />
      )}
    </Card>
  );
};

export default ChatContainer;
