import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import GlobalAnalytic from "./components/GlobalAnalytics";
import useCommon from "../../hooks/useCommon";

const Analytics = () => {
  const { setBreadCrumbs } = useCommon();
  useEffect(() => {
    setBreadCrumbs?.([{ label: "Analytic" }]);
  }, [setBreadCrumbs]);

  return (
    <Box width="100%">
      <Paper sx={{ p: "24px", mb: "15px" }} elevation={1}>
        <GlobalAnalytic isSearch />
      </Paper>
    </Box>
  );
};

export default Analytics;
