import { Avatar, Box, Typography, Tooltip } from "@mui/material";
import React, { FC, memo } from "react";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useModal } from "mui-modal-provider";
import moment from "moment";
import { ChatUser, MessageType } from "../../SupportChatType";
import ShowFileModal from "../../../../components/modals/ShowFileModal";
type MessageItemProps = {
  message: MessageType;
  users: {
    [key: number]: ChatUser[];
  };
};

const MessageItem: FC<MessageItemProps> = ({ users, message }) => {
  const { showModal } = useModal();
  const handleShowModal = (media: any) => {
    const modal = showModal(ShowFileModal, {
      fileName: media.original_filename,
      url: media.additional_info.pdf_url
        ? media.additional_info.pdf_url
        : media.url,
      onCancel: () => {
        modal.hide();
      },
    });
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        p: "10px 20px",
        m: "7px 0",
        "&:hover": {
          background: theme.palette.action.hover,
        },
      })}
    >
      <Box>
        <Avatar
          src={`${
            (users[message?.user_id]?.[0] &&
              users[message.user_id][0].avatar?.additional_info?.size_urls
                ?.avatar_icon) ||
            null
          }`}
        />
      </Box>
      <Box sx={{ ml: "10px", flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">
            {users[message.user_id]?.[0]?.full_name}
          </Typography>
          <Typography variant="caption">
            {moment.utc(message.created_at).local().format("HH:mm MM-DD-YYYY")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">{message.body}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {message.media !== null &&
            message.media.map((media) => (
              <Tooltip title={media.original_filename}>
                <Box
                  onClick={() => handleShowModal(media)}
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "50px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <InsertDriveFileIcon />
                  </Box>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      overflow: "hidden",
                    }}
                  >
                    {media.original_filename}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {message.status === "seen" ? (
            <DoneAllIcon
              sx={(theme) => ({
                width: "16px",
                color: theme.palette.primary.main,
              })}
            />
          ) : (
            <DoneIcon sx={{ width: "16px" }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(MessageItem);
