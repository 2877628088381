import React, { FC, useCallback, useMemo, useState } from "react";
import { BreadCrumbs, CommonContext, User } from "../../context/CommonContext";

type CommonProviderProps = {
  children: React.ReactNode;
};

const CommonProvider: FC<CommonProviderProps> = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [token, setTokenData] = useState("");
  const [breadCrumbs, setBreadCrumbsData] = useState<BreadCrumbs[]>([]);
  const [profileData, setProfileData] = useState<User | null>(null);

  const setToken = useCallback((tokenData: string) => {
    setTokenData(tokenData);

    if (tokenData) {
      localStorage.setItem("dashboard-token", tokenData);
    } else {
      localStorage.removeItem("dashboard-token");
    }
  }, []);

  const setBreadCrumbs = useCallback((breadCrumbsData: BreadCrumbs[]) => {
    setBreadCrumbsData(breadCrumbsData);
  }, []);

  const setProfile = useCallback((user: User | null) => {
    setProfileData(user);
  }, []);

  const setAuth = useCallback((auth: boolean) => {
    setIsAuth(auth);
  }, []);

  const contextValue = useMemo(
    () => ({
      isAuth,
      setAuth,
      token,
      setToken,
      breadCrumbs,
      setBreadCrumbs,
      profile: profileData,
      setProfile,
    }),
    [
      isAuth,
      setAuth,
      token,
      setToken,
      breadCrumbs,
      setBreadCrumbs,
      profileData,
      setProfile,
    ]
  );

  return (
    <CommonContext.Provider value={contextValue}>
      {children}
    </CommonContext.Provider>
  );
};

export default CommonProvider;
