import React, { FC } from "react";
import { Box, TableCell, TableRow, Typography } from "@mui/material";

import moment from "moment";
import { capitalize } from "lodash";
import { UsersType } from "./Users.type";

type SubscribersRowProps = {
  data: UsersType;
};

export enum RegistrationStepsEnum {
  slider = "Slider",
  about = "About",
  tools_new = "Tools",
  setup = "Setup",
  package_new = "Packages",
  purchase = "Purchase",
}

function getNextStepKey(
  currentStepKey: keyof typeof RegistrationStepsEnum
): RegistrationStepsEnum | undefined {
  const keys = Object.keys(RegistrationStepsEnum) as Array<
    keyof typeof RegistrationStepsEnum
  >;
  const currentIndex = keys.indexOf(currentStepKey);

  if (currentIndex === -1 || currentIndex + 1 >= keys.length) {
    return undefined;
  }

  const nextKey = keys[currentIndex + 1];
  return RegistrationStepsEnum[nextKey];
}

const UsersRow: FC<SubscribersRowProps> = ({ data }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{`${data.id}${
          data.analytics?.platform ? ` - ${data.analytics?.platform}` : ""
        }`}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.created_at
            ? moment.utc(data.created_at).local().format("LLL")
            : "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.full_name}</Typography>
      </TableCell>
      <TableCell scope="row">
        <Typography variant="body2">{data.email ? data.email : "-"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{data.phone ? data.phone : "-"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.last_step ? getNextStepKey(data.last_step as any) : "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{`${data.package ?? "-"} ${
          data.package_from ?? "-"
        } ${data.is_used_trial ? "( Trial )" : ""} ${
          data?.package_period ? capitalize(data?.package_period) : ""
        }`}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.package_canceled_at
            ? moment(data.package_canceled_at).format("LLL,  h:mm A")
            : "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="body2">
          {data.deleted_at ? moment(data.deleted_at).format("L") : "-"}
        </Typography>
      </TableCell>

      <TableCell size="small" align="center" component="th" scope="row">
        <Box>
          {data.is_skip_onboarding && <Box mr="4px">onboadring_skip</Box>}

          {data.is_view_onboarding && <Box mr="4px">purchase completed</Box>}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UsersRow;
