import { useEffect } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { useSnackbar } from "notistack";
import { useApi, useFetcher } from "../../../hooks/useApi";
import { Post, Tags, TagsTwo } from "../Post.types";
import PostItemFields from "../components/PostItemFields";
import DefaultForm from "../../../components/form/DefaultForm";
import PostToolBar from "../components/PostToolBar/PostToolBar";
import useCommon from "../../../hooks/useCommon";

type PostItemForm = {
  data: Post;
};

const PostItem = () => {
  const { id } = useParams();
  const api = useApi();
  const fetcher = useFetcher();
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadCrumbs } = useCommon();
  const { data, mutate } = useSWR<Post>(["/blog/posts", id], fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    setBreadCrumbs?.([
      { label: "Posts", url: "/posts" },
      { label: "Edit Post" },
    ]);
  }, [setBreadCrumbs]);

  const onSubmit = async (values: PostItemForm) => {
    if (values.data.file === null) {
      enqueueSnackbar("Background photo is required", {
        variant: "error",
      });
      return;
    }
    try {
      const newData = {
        ...values.data,
        file: { token: values.data.file.token },
        tags: values.data.tags.map((elem: TagsTwo | Tags) => {
          if (elem.inputValue) {
            return { name: elem.inputValue };
          }
          return elem;
        }),
      };
      const res = await api.put(`/blog/posts/${id}`, newData);
      await mutate(res.data);
      enqueueSnackbar("File successful updated", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  if (!data) return <>Loading...</>;

  const initialData = {
    data: {
      ...data,
      tags:
        data.tags.length > 0
          ? data.tags.map((elem) => {
              return { name: elem.tag };
            })
          : [],
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DefaultForm<PostItemForm> data={initialData} onSubmit={onSubmit}>
        <PostToolBar title={data.title} id={data.id} />
        <PostItemFields
          description={data.description}
          defaultCategoryId={data.category_id}
        />
      </DefaultForm>
    </Box>
  );
};

export default PostItem;
