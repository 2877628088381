import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import { Field, FormSpy } from "react-final-form";
import {
  Card,
  ClickAwayListener,
  IconButton,
  Input,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { Picker } from "emoji-mart";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";

type ChatFootersFieldsProps = {
  loading: boolean;
};

const ChatFootersFields: FC<ChatFootersFieldsProps> = ({ loading }) => {
  const [showEmoji, setShowEmoji] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box sx={{ maxHeight: "250px", p: "0 20px" }}>
      <Box sx={{ position: "relative" }}>
        <Field name="body">
          {({ input }) => (
            <TextField
              {...input}
              id="standard-multiline-flexible"
              label="Message"
              fullWidth
              multiline
              maxRows={3}
              minRows={1}
              disabled={loading}
              sx={{
                marginTop: "20px",
              }}
              variant="outlined"
            />
          )}
        </Field>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <IconButton
                disabled={loading}
                onClick={() => setShowEmoji(!showEmoji)}
              >
                <InsertEmoticonIcon />
              </IconButton>

              {showEmoji && (
                <ClickAwayListener onClickAway={() => setShowEmoji(false)}>
                  <Card>
                    <FormSpy>
                      {({ form }) => (
                        <Picker
                          style={{
                            position: "fixed",
                            bottom: "100px",
                            zIndex: "1",
                          }}
                          showSkinTones={false}
                          onSelect={(event) => {
                            if ("native" in event) {
                              form.change(
                                "body",
                                (form.getState().values.body || "") +
                                  event.native
                              );
                            }
                          }}
                        />
                      )}
                    </FormSpy>
                  </Card>
                </ClickAwayListener>
              )}
            </Box>
            <Box>
              <FormSpy>
                {({ form }) => (
                  <label htmlFor="icon-button-file">
                    <Input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const fileList = e.target.files || [];
                        if (!fileList.length) return;
                        if (fileList.length > 10) {
                          enqueueSnackbar("Max 10 files", {
                            variant: "warning",
                          });
                          form.mutators.concat(
                            "files",
                            Array.from(fileList).slice(0, 10)
                          );
                        } else {
                          form.mutators.concat("files", Array.from(fileList));
                        }
                      }}
                      sx={{ display: "none" }}
                      id="icon-button-file"
                      inputProps={{
                        multiple: true,
                      }}
                      type="file"
                    />

                    <IconButton
                      disabled={
                        form.getState().values.files.length === 10 || loading
                      }
                      component="span"
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                )}
              </FormSpy>
            </Box>
          </Box>
          <LoadingButton loading={loading} type="submit" size="small">
            Sent
          </LoadingButton>
        </Box>
        <Box
          sx={{
            margin: "10px 0",
          }}
        >
          <FormSpy>
            {({ form }) => (
              <Box
                sx={{
                  display: "flex",
                  maxHeight: "100px",
                  flexDirection: "row",
                  maxWidth: "100%",
                  flexWrap: "wrap",
                  overflowY: "scroll",
                  padding: "2px",
                }}
              >
                {form.getState().values.files.map((el: any, index: number) => (
                  <Paper
                    sx={{
                      height: "40px",
                      maxWidth: "180px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                    key={index}
                  >
                    <Typography
                      sx={{
                        paddingLeft: "5px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "180px",
                        overflow: "hidden",
                      }}
                      variant="body1"
                    >
                      {el.name}
                    </Typography>
                    <IconButton
                      onClick={() => form.mutators.remove("files", index)}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Paper>
                ))}
              </Box>
            )}
          </FormSpy>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatFootersFields;
