import { GlobalOnboardingData } from "../OnboardingData.types";

export const dataFormat = (date: GlobalOnboardingData) => {
  const newObject = {
    "13-17 y.o": 0,
    "18-24 y.o": 0,
    "25-34 y.o": 0,
    "35-44 y.o": 0,
    "45-54 y.o": 0,
    "55-64 y.o": 0,
    "65-74 y.o": 0,
  };
  Object.keys(date.age).map((item) => {
    if (+item >= 13 && +item <= 17) {
      newObject["13-17 y.o"] += date.age[item];
    }
    if (+item >= 18 && +item <= 24) {
      newObject["18-24 y.o"] += date.age[item];
    }
    if (+item >= 25 && +item <= 34) {
      newObject["25-34 y.o"] += date.age[item];
    }
    if (+item >= 35 && +item <= 44) {
      newObject["35-44 y.o"] += date.age[item];
    }
    if (+item >= 45 && +item <= 54) {
      newObject["45-54 y.o"] += date.age[item];
    }
    if (+item >= 55 && +item <= 64) {
      newObject["55-64 y.o"] += date.age[item];
    }
    if (+item >= 65 && +item <= 74) {
      newObject["65-74 y.o"] += date.age[item];
    }
    return "";
  });

  return { ...date, age: newObject };
};
