import { createContext } from "react";
import {
  MessageType,
  ResponseMeta,
  ThreadItemType,
} from "../../pages/SupportChat/SupportChatType";

type ISupportChatContext = {
  setThreadsState: (data: {
    data: ThreadItemType[];
    meta: ResponseMeta;
  }) => void;
  setSelectedThreadState: (data: ThreadItemType | null) => void;
  threads: ThreadItemType[];
  selectedThread: ThreadItemType | null;
  setMessagesState: (data: MessageType[]) => void;
  messages: MessageType[];
  addMessage: (message: MessageType, isSocket: boolean) => void;
  resetMessages: () => void;
  resetSelectedThread: () => void;
  setSeenMessage: () => void;
  addThread: (thread: ThreadItemType) => void;
  sortThreads: (id: number) => void;
  setThreadCounter: (id: number, count: number) => void;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const SupportChatContext = createContext<ISupportChatContext>({
  setThreadsState: (data) => {},
  threads: [],
  setSelectedThreadState: (data) => {},
  setMessagesState: (data) => {},
  selectedThread: null,
  messages: [],
  addMessage: (data, isSocket) => {},
  resetMessages: () => {},
  resetSelectedThread: () => {},
  setSeenMessage: () => {},
  addThread: (data) => {},
  sortThreads: (id) => {},
  setThreadCounter: (id, count) => {},
});
/* eslint-enable  @typescript-eslint/no-unused-vars */
