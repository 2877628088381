interface FormData {
  seo_title: string;
  seo_description: string;
  category_id: number | null;
  title: string;
  description: string;
  file?: {
    token: string;
  };
}

interface ValidationErrors {
  data?: Partial<{
    seo_title: string;
    seo_description: string;
    category_id: string;
    title: string;
    description: string;
  }>;
}

export const validate = (values: { data: FormData }): ValidationErrors => {
  const errors: ValidationErrors = {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { seo_title, seo_description, category_id, title, description } =
    values.data;

  if (!seo_title) {
    errors.data = {
      ...errors.data,
      seo_title: "The seo title field is required.",
    };
  } else if (seo_title.length < 2 || seo_title.length > 80) {
    errors.data = {
      ...errors.data,
      seo_title: "The seo title must be between 2 and 80 characters.",
    };
  }

  if (!seo_description) {
    errors.data = {
      ...errors.data,
      seo_description: "The seo description field is required.",
    };
  } else if (seo_description.length < 3 || seo_description.length > 290) {
    errors.data = {
      ...errors.data,
      seo_description:
        "The seo description must be between 3 and 290 characters.",
    };
  }

  if (!category_id) {
    errors.data = {
      ...errors.data,
      category_id: "The category field is required.",
    };
  }

  if (!title) {
    errors.data = { ...errors.data, title: "The title field is required." };
  } else if (title.length < 2 || title.length > 255) {
    errors.data = {
      ...errors.data,
      title: "The title must be between 2 and 255 characters.",
    };
  }

  if (!description) {
    errors.data = {
      ...errors.data,
      description: "The description field is required.",
    };
  }

  return errors;
};
