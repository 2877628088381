import { Box, Collapse, TextField } from "@mui/material";
import React, { FC } from "react";
import { TransitionGroup } from "react-transition-group";
import ThreadItem from "./ThreadItem";
import { ThreadItemType } from "../SupportChatType";
import Stub from "../../../components/Stub";

type ThreadListProps = {
  data: ThreadItemType[];
  handleChangeSearch: (value: string) => void;
  isStopPagination: boolean;
  getThreadList: () => void;
  showNotFoundStub: boolean;
};

const ThreadList: FC<ThreadListProps> = ({
  data,
  handleChangeSearch,
  isStopPagination,
  getThreadList,
  showNotFoundStub,
}) => {
  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      !isStopPagination
    ) {
      getThreadList();
    }
  };

  return (
    <Box sx={{ width: "30%", paddingRight: "20px" }}>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: "10px",
        }}
      >
        <TextField
          label="Search"
          fullWidth
          size="small"
          placeholder="Search..."
          onChange={(e) => handleChangeSearch(e.target.value)}
        />
      </Box>

      <Box
        onScroll={handleScroll}
        sx={{
          height: `calc(100% - 61px)`,
          overflowY: "auto",
        }}
      >
        <TransitionGroup>
          {data.map((item) => (
            <Collapse key={item.id}>
              <ThreadItem data={item} />
            </Collapse>
          ))}

          {showNotFoundStub && (
            <Collapse>
              <Box sx={{ height: "90px" }}>
                <Stub text="Not found" />
              </Box>
            </Collapse>
          )}
        </TransitionGroup>
      </Box>
    </Box>
  );
};

export default ThreadList;
