export const valueKey: { [key: string]: string } = {
  count_deactivated: "Deactivated",
  count_registration: "Registrations",
  count_undone_onboarding: "Undone-Onb",
  count_packages: "Buy",
  count_canceled_packages: "Cancel",
  count_packages_web: "Buy_Web",
  count_packages_play_store: "Buy_Andr",
  count_packages_app_store: `Buy_Ios`,
  count_trial: "Trial",
};
