import Global from "@mui/material/GlobalStyles";
import theme from "../../theme";

const GlobalStyles = (
  <Global
    styles={{
      "*": {
        "::-webkit-scrollbar": {
          width: "4px",
        },

        /* Track */
        "::-webkit-scrollbar-track": {
          background: "transparency",
        },

        /* Handle */
        "::-webkit-scrollbar-thumb": {
          background: theme.palette.primary.main,
        },

        /* Handle on hover */
        "::-webkit-scrollbar-thumb:hover": {
          background: theme.palette.primary.light,
        },
      },
    }}
  />
);

export default GlobalStyles;
