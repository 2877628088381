import { GlobalOnboardingData } from "../OnboardingData.types";
import { dataFormat } from "./dataFormat.types";
import { AnalyticsFiltersForm } from "../../Analytics/analitytics.types";

type Props = {
  setData: (value: GlobalOnboardingData) => void;
  setLoading: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  searchParams: AnalyticsFiltersForm;
  api: any;
  id?: number;
};
export const getAnalyticsData = async (
  setData: (value: GlobalOnboardingData) => void,
  setLoading: (value: boolean | ((prevVar: boolean) => boolean)) => void,
  searchParams: AnalyticsFiltersForm,
  api: any,
  id?: number
): Promise<Props | undefined> => {
  setLoading(true);
  const res: { data: GlobalOnboardingData } = await api.get(
    `/user-info/onboarding?${
      searchParams.dateFrom
        ? `date_time_from=${searchParams.dateFrom} 00:00:00&`
        : ""
    }${
      searchParams.dateTo ? `date_time_to=${searchParams.dateTo} 23:59:59&` : ""
    }${searchParams.search ? `search=${searchParams.search}&` : ""}${
      id ? `user_id=${id}` : ""
    }${searchParams.search ? `search=${searchParams.search}&` : ""}`
  );
  setLoading(false);

  if (!!id) {
    setData(res.data);
    return;
  }
  setData(dataFormat(res.data));
};
