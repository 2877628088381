export enum AnalyticEnum {
  todo = "todo",
  planner = "planner",
  garage = "garage",
  login = "login",
  archive = "archive",
  network = "network",
}

export enum AnalyticTimePreset {
  allTime = "allTime",
  yesterday = "yesterday",
  today = "today",
  week = "week",
  month = "month",
}
