import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";

import { Controller, useForm, useWatch } from "react-hook-form";
import moment from "moment";
import { timeSelectConfig } from "../../analytic.config";
import { AnalyticTimePreset } from "../../analytic.enum";
import { AnalyticsFiltersForm } from "../../analitytics.types";

const defaultValues = {
  search: "",
  dateFrom: null,
  dateTo: null,
  preset: AnalyticTimePreset.allTime,
};

type Props = {
  isSearch: boolean;
  handleSearch: (form: AnalyticsFiltersForm) => void;
};

// TODO global component
const AnalyticsFilters: FC<Props> = ({ isSearch, handleSearch }) => {
  const { control, handleSubmit, setValue } = useForm<AnalyticsFiltersForm>({
    defaultValues,
  });

  const presetState = useWatch({ control, name: "preset" });

  useMemo(() => {
    if (presetState === AnalyticTimePreset.allTime) {
      setValue("dateTo", "");
      setValue("dateFrom", "");
    }
    if (presetState === AnalyticTimePreset.today) {
      setValue("dateFrom", moment().format("YYYY-MM-DD"));
      setValue("dateTo", moment().format("YYYY-MM-DD"));
    }
    if (presetState === AnalyticTimePreset.yesterday) {
      setValue("dateFrom", moment().subtract(1, "day").format("YYYY-MM-DD"));
      setValue("dateTo", moment().subtract(1, "day").format("YYYY-MM-DD"));
    }
    if (presetState === AnalyticTimePreset.week) {
      setValue("dateFrom", moment().subtract(1, "week").format("YYYY-MM-DD"));
      setValue("dateTo", moment().format("YYYY-MM-DD"));
    }
    if (presetState === AnalyticTimePreset.month) {
      setValue("dateFrom", moment().subtract(1, "month").format("YYYY-MM-DD"));
      setValue("dateTo", moment().format("YYYY-MM-DD"));
    }
  }, [presetState]);

  const handleChangeDate = useCallback(() => {
    setValue("preset", "");
  }, []);

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(handleSearch)}>
        <Box display="flex" alignItems="center">
          {isSearch && (
            <Box>
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: 220 }}
                    id="type_id"
                    placeholder="Search..."
                    label="Search"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Box>
          )}

          <Box sx={{ width: 200, ml: "15px" }}>
            <Controller
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date preset
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...field}
                    label="Date preset"
                  >
                    {timeSelectConfig.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="preset"
            />
          </Box>

          <Box>
            <Controller
              name="dateFrom"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="date"
                  label="Start date"
                  type="date"
                  variant="standard"
                  onChange={(e) => {
                    field.onChange(e);
                    handleChangeDate();
                  }}
                  sx={{ width: 150, marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            <Controller
              name="dateTo"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="date"
                  label="End date"
                  variant="standard"
                  type="date"
                  onChange={(e) => {
                    field.onChange(e);
                    handleChangeDate();
                  }}
                  sx={{ width: 150, marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box ml="5px">
            <Button type="submit"> Apply </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AnalyticsFilters;
