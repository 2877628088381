import React from "react";
import { Collapse, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { render } from "react-dom";
import ModalProvider from "mui-modal-provider";
import theme from "./theme";
import App from "./App";
import CommonProvider from "./providers/CommonProvider";
import GlobalStyles from "./components/globalStyle/Global.style";

import CountersProvider from "./providers/CountersProvider";
import SupportChatProvider from "./providers/SupportChatProvider";

render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <Router>
        <CommonProvider>
          <CountersProvider>
            <SupportChatProvider>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                TransitionComponent={Collapse}
              >
                <ModalProvider>
                  {GlobalStyles}
                  <App />
                </ModalProvider>
              </SnackbarProvider>
            </SupportChatProvider>
          </CountersProvider>
        </CommonProvider>
      </Router>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
