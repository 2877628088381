import React, { FC } from "react";
import { Button } from "@mui/material";
import { FormSpy } from "react-final-form";

type EditMessageModalFooterProps = {
  onCancel: () => void;
};

const EditCategoryModalFooter: FC<EditMessageModalFooterProps> = ({
  onCancel,
}) => {
  return (
    <FormSpy
      subscription={{
        dirty: true,
        pristine: true,
        touched: true,
      }}
    >
      {({ pristine, touched, form }) => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            sx={{ marginLeft: "auto", marginRight: "1rem" }}
            color="primary"
            onClick={() => form.reset()}
          >
            Reset
          </Button>
          <Button
            type="submit"
            disabled={pristine || !touched}
            color="secondary"
          >
            Save
          </Button>
        </>
      )}
    </FormSpy>
  );
};

export default EditCategoryModalFooter;
