import {
  Box,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useApi } from "../../hooks/useApi";
import ChatReleaseRow from "./ChatReleaseRow";
import { MessageType } from "../SupportChat/SupportChatType";
import useCommon from "../../hooks/useCommon";

const ChatRelease = () => {
  const api = useApi();
  const [threadId, seThreadId] = useState(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [lastMessageId, setLastMessageId] = useState(0);
  const [isStopPagination, setIsStopPagination] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadCrumbs } = useCommon();

  const getMessages = async (id: number) => {
    setLoading(true);
    try {
      const resMessages = await api.get(
        `/threads/${id}/messages?id=${
          lastMessageId === 0 ? "" : lastMessageId
        }&&direction=0`
      );

      setMessages((prev) => [...prev, ...resMessages.data.data]);

      if (resMessages.data.data.length < 15) {
        setIsStopPagination(true);
      }
      setLastMessageId(
        resMessages.data.data[resMessages.data.data.length - 1].id
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const getReleaseThread = async () => {
    try {
      const resThread = await api.get("/admin/threads/release");
      seThreadId(resThread.data.id);
      getMessages(resThread.data.id);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDeleteMessage = async (id: number) => {
    try {
      await api.delete(`/messages/${id}`);
      setMessages((prev) => prev.filter((item) => item.id !== id));
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleReloadMessages = async () => {
    try {
      const resMessages = await api.get(
        `/threads/${threadId}/messages?direction=0`
      );

      setMessages(resMessages.data.data);

      if (resMessages.data.data.length < 15) {
        setIsStopPagination(true);
      }
      setLastMessageId(
        resMessages.data.data[resMessages.data.data.length - 1].id
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useEffect(() => {
    getReleaseThread();
    setBreadCrumbs?.([{ label: "Release chat" }]);
    return () => {
      setMessages([]);
      setLastMessageId(0);
      setIsStopPagination(false);
    };
  }, []);

  const skeletonArray = Array(15).fill(Array(4).fill(""));

  return (
    <Card sx={{ width: "100%" }}>
      <Toolbar>
        <Box
          sx={{
            width: "100%",
            margin: "20px 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Chat realise
        </Box>
        <Box>
          <LoadingButton
            loading={!threadId}
            component={Link}
            to={`/chat_update/create/${threadId}`}
            variant="outlined"
          >
            Add
          </LoadingButton>
        </Box>
      </Toolbar>
      <TableContainer>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "5%" }} />
              <TableCell sx={{ width: "65%" }}>Message</TableCell>
              <TableCell sx={{ width: "20%" }}>Reactions</TableCell>
              <TableCell sx={{ width: "10%" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.length
              ? messages.map((item: MessageType) => (
                  <ChatReleaseRow
                    data={item}
                    handleDeleteMessage={handleDeleteMessage}
                    handleReloadMessages={handleReloadMessages}
                  />
                ))
              : skeletonArray.map((item, index: number) => (
                  <TableRow key={index}>
                    {item.map((el: string[], ind: number) => (
                      <TableCell key={ind} component="th" scope="row">
                        <Skeleton
                          animation="wave"
                          variant="text"
                          height="50px"
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingButton
          loading={loading}
          disabled={isStopPagination}
          onClick={getReleaseThread}
        >
          LoadMore
        </LoadingButton>
      </Box>
    </Card>
  );
};

export default ChatRelease;
