import React, { FC } from "react";
import {
  Avatar,
  Box,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "react-final-form";
import { UserFieldBox } from "./User.style";

type UserFieldsProps = {
  isCreate?: boolean;
};

const UserFields: FC<UserFieldsProps> = ({ isCreate = false }) => {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-around",
        paddingTop: "40px",
      }}
    >
      {isCreate === false && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Field subscription={{ value: true }} name="avatar">
              {({ input }) => (
                <Avatar
                  sx={{ width: "100px", height: "100px" }}
                  alt="avatar"
                  src={input.value}
                />
              )}
            </Field>
          </Box>
          <UserFieldBox>
            <Field subscription={{ value: true }} name="last_activated_at">
              {({ input }) => (
                <TextField
                  {...input}
                  fullWidth
                  label="Last activated at"
                  disabled
                  variant="standard"
                />
              )}
            </Field>
          </UserFieldBox>
          <UserFieldBox>
            <Field subscription={{ value: true }} name="created_at">
              {({ input }) => (
                <TextField
                  {...input}
                  fullWidth
                  label="Created at"
                  disabled
                  variant="standard"
                />
              )}
            </Field>
          </UserFieldBox>
        </Box>
      )}

      <Box>
        <UserFieldBox>
          <Field name="first_name">
            {({ input, meta }) => (
              <TextField
                {...input}
                fullWidth
                label="First name"
                required
                variant="standard"
                error={(meta.error || meta.submitError) && meta.touched}
                helperText={
                  (meta.error || meta.submitError) && meta.touched
                    ? meta.error || meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </UserFieldBox>
        <UserFieldBox>
          <Field name="last_name">
            {({ input, meta }) => (
              <TextField
                {...input}
                fullWidth
                label="Last name"
                required
                variant="standard"
                error={(meta.error || meta.submitError) && meta.touched}
                helperText={
                  (meta.error || meta.submitError) && meta.touched
                    ? meta.error || meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </UserFieldBox>
        <UserFieldBox>
          <Field name="email">
            {({ input, meta }) => (
              <TextField
                {...input}
                fullWidth
                label="Email"
                disabled={!isCreate}
                variant="standard"
                error={(meta.error || meta.submitError) && meta.touched}
                helperText={
                  (meta.error || meta.submitError) && meta.touched
                    ? meta.error || meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </UserFieldBox>
        {isCreate === false && (
          <UserFieldBox>
            <Field name="phone">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  fullWidth
                  label="Phone"
                  disabled={!isCreate}
                  variant="standard"
                  error={(meta.error || meta.submitError) && meta.touched}
                  helperText={
                    (meta.error || meta.submitError) && meta.touched
                      ? meta.error || meta.submitError
                      : ""
                  }
                />
              )}
            </Field>
          </UserFieldBox>
        )}
      </Box>
      <Box>
        <UserFieldBox>
          <Field name="birth_day">
            {({ input, meta }) => (
              <TextField
                {...input}
                required
                fullWidth
                label="Birthday"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                error={(meta.error || meta.submitError) && meta.touched}
                helperText={
                  (meta.error || meta.submitError) && meta.touched
                    ? meta.error || meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </UserFieldBox>
        <UserFieldBox>
          <Field name="gender">
            {({ input, meta }) => (
              <FormControl
                required
                error={(meta.error || meta.submitError) && meta.touched}
                variant="standard"
                fullWidth
              >
                {}
                <InputLabel variant="standard" id="demo-simple-select-label">
                  Gender
                </InputLabel>
                <Select variant="standard" {...input} label="Gender">
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {(meta.error || meta.submitError) && meta.touched && (
                  <FormHelperText error>
                    {meta.error || meta.submitError}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Field>
        </UserFieldBox>
        <UserFieldBox>
          <Field name="password">
            {({ input, meta }) => (
              <TextField
                {...input}
                fullWidth
                label="Password"
                variant="standard"
                disabled={!isCreate}
                error={(meta.error || meta.submitError) && meta.touched}
                helperText={
                  (meta.error || meta.submitError) && meta.touched
                    ? meta.error || meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </UserFieldBox>
        <UserFieldBox sx={{ marginBottom: "40px" }}>
          <Field name="password_confirmation">
            {({ input, meta }) => (
              <TextField
                {...input}
                fullWidth
                label="Password confirmation"
                variant="standard"
                disabled={!isCreate}
                error={(meta.error || meta.submitError) && meta.touched}
                helperText={
                  (meta.error || meta.submitError) && meta.touched
                    ? meta.error || meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </UserFieldBox>
      </Box>
    </Card>
  );
};

export default UserFields;
