import { FC, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useModal } from "mui-modal-provider";
import moment from "moment";
import { Post } from "./Post.types";
import ConfirmModal from "../../components/modals/ConfirmModal";

type PostRowProps = {
  item: Post;
  handleDelete: (id: number) => void;
};

const PostRow: FC<PostRowProps> = ({ item, handleDelete }) => {
  const [open, setOpen] = useState(false);
  const { showModal } = useModal();

  const handleOpenConfirmationDialog = (id: number) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete Post #${id}`,
      body: "Are you sure you want to delete this item?",
      onConfirm: () => {
        handleDelete(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const renderTags = () => {
    if (item.tags.length === 0) return null;

    return item.tags.map((tag, index) => (
      <span key={index}>
        {tag.tag}
        {index < item.tags.length - 1 ? ", " : " "}
      </span>
    ));
  };

  return (
    <>
      <TableRow key={item.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          <Typography variant="body2">{item.id}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2">{item.title}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2">{item.category?.title || "-"}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2">{renderTags()}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2">
            {moment(item.created_at).format("DD.MM.YYYY")}
          </Typography>
        </TableCell>
        <TableCell size="small" align="center" component="th" scope="row">
          <Box>
            <IconButton size="small" component={Link} to={`/posts/${item.id}`}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenConfirmationDialog(item.id)}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Box
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PostRow;
