import React, { FC, useMemo, useState } from "react";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import useSWR from "swr";
import AnalyticsFilters from "../filters";
// eslint-disable-next-line import/no-cycle
import { useFetcher } from "../../../../hooks/useApi";
import { AnalyticTimePreset } from "../../analytic.enum";
import { AnalyticsFiltersForm } from "../../analitytics.types";
import { valueKey } from "./titleKeyObject.types";

export type AnalyticItem = {
  [key: string]: number;
};

export type GlobalAnalyticData = {
  [key: string]: AnalyticItem;
};

type Props = {
  id?: number;
  title?: string;
  isSearch?: boolean;
};
const GlobalAnalytic: FC<Props> = ({
  id,
  title = "Global Analytic",
  isSearch = false,
}) => {
  const fetcher = useFetcher();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useState<AnalyticsFiltersForm>({
    search: "",
    dateFrom: null,
    dateTo: null,
    preset: AnalyticTimePreset.allTime,
  });

  const searchParamsString = useMemo(() => {
    return `${
      searchParams.dateFrom
        ? `date_time_from=${searchParams.dateFrom} 00:00:00&`
        : ""
    }${
      searchParams.dateTo ? `date_time_to=${searchParams.dateTo} 23:59:59&` : ""
    }${searchParams.search ? `search=${searchParams.search}&` : ""}${
      id ? `user_id=${id}` : ""
    }`;
  }, [searchParams]);

  const { data: totalEarnings } = useSWR(
    ["user-info/total-earnings", `?${searchParamsString}`],
    fetcher
  );
  const { data: completedRenewal } = useSWR(
    ["user-info/completed-renewal", `?${searchParamsString}`],
    fetcher
  );

  const { data: onboardingInfo } = useSWR(
    ["/user-info/onboarding", `?${searchParamsString}`],
    fetcher
  );

  const formattedDashboard =
    totalEarnings && completedRenewal
      ? {
          canceled_accounts: totalEarnings?.canceled_accounts || 0,
          expected: totalEarnings?.expected ? totalEarnings.expected / 100 : 0,
          total: completedRenewal?.total ? completedRenewal.total / 100 : 0,
        }
      : null;
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box>
          <AnalyticsFilters
            isSearch={isSearch}
            handleSearch={(form) => setSearchParams(form)}
          />
        </Box>
      </Box>
      <Box>
        {formattedDashboard ? (
          <>
            <Table
              sx={{
                border: `1px solid rgba(224, 224, 224, 1)`,
                borderRadius: "5px",
                marginBottom: "25px",
              }}
              size="small"
              aria-label="purchases"
            >
              <TableHead>
                <TableRow sx={{ background: theme.palette.action.hover }}>
                  {Object.keys(formattedDashboard).map(
                    (item: string, index: number) => (
                      <TableCell key={index}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          {valueKey[item]}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(formattedDashboard).map(
                    (value: any, index) => (
                      <TableCell key={index}>
                        <Typography variant="body1">{value}</Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <>
            <Box
              sx={{
                p: "20px 0 0 10px",
              }}
            />
            <Skeleton animation="wave" variant="text" height="100px" />
          </>
        )}
      </Box>

      <Box>
        {onboardingInfo?.registration_tools ? (
          <>
            <Table
              sx={{
                border: `1px solid rgba(224, 224, 224, 1)`,
                borderRadius: "5px",
                marginBottom: "25px",
              }}
              size="small"
              aria-label="purchases"
            >
              <TableHead>
                <TableRow sx={{ background: theme.palette.action.hover }}>
                  {Object.keys(onboardingInfo.registration_tools).map(
                    (item: string, index: number) => (
                      <TableCell key={index}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          {valueKey[item]}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(onboardingInfo?.registration_tools).map(
                    (value: any, index) => (
                      <TableCell key={index}>
                        <Typography variant="body1">{value}</Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <>
            <Box
              sx={{
                p: "20px 0 0 10px",
              }}
            />
            <Skeleton animation="wave" variant="text" height="100px" />
          </>
        )}
      </Box>
    </Box>
  );
};

export default GlobalAnalytic;
