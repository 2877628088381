import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import GroupIcon from "@mui/icons-material/Group";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import Cancel from "@mui/icons-material/Cancel";

import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";

const routes = [
  {
    group: "Main",
    items: [
      {
        path: "/dashboard",
        label: "Home",
        icon: HomeIcon,
      },
    ],
  },
  {
    group: "Posts",
    items: [
      {
        path: "/posts",
        label: "Posts",
        icon: PostAddIcon,
      },
    ],
  },
  {
    group: "Analytics",
    items: [
      {
        path: "/users-list",
        label: "Users list",
        icon: AnalyticsIcon,
      },
      {
        path: "/analytics",
        label: "Analytic",
        icon: AnalyticsOutlinedIcon,
      },
      {
        path: "/onboarding-data",
        label: "Onboarding data",
        icon: AutoGraphIcon,
      },
    ],
  },
  {
    group: "Users",
    items: [
      {
        path: "/users",
        label: "Users",
        icon: GroupIcon,
      },
      {
        path: "/subscriptions",
        label: "Subscriptions",
        icon: PeopleOutlineIcon,
      },
    ],
  },
  {
    group: "Feedback",
    items: [
      {
        path: "/cancel-feedback",
        label: "Cancel Feedback",
        icon: Cancel,
      },
      {
        path: "/feedback",
        label: "Feedback",
        icon: FeedbackOutlinedIcon,
      },
      {
        path: "/help-center",
        label: "Help center",
        icon: HelpCenterOutlinedIcon,
      },
    ],
  },
  {
    group: "Support",
    items: [
      {
        path: "/chat_update",
        label: "Update chat",
        icon: BrowserUpdatedIcon,
      },
      {
        path: "/chat_support",
        label: "Chat support",
        icon: SupportAgentIcon,
      },
    ],
  },
];

export default routes;
