import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { ChatUser, MessageType } from "../../SupportChatType";
import MessageItem from "./MessageItem";
import Stub from "../../../../components/Stub";

type MessageContainerProps = {
  ref: React.Ref<any>;
  users: {
    [key: number]: ChatUser[];
  };
  messageData: MessageType[];
  setLastId: (id: number) => void;
  lastId: number;
  isStopPagination: boolean;
  showStub: boolean;
  isActive: boolean;
};

const MessageContainer: FC<MessageContainerProps> = forwardRef(
  (
    {
      users,
      messageData,
      setLastId,
      lastId,
      isStopPagination,
      showStub,
      isActive,
    },
    ref
  ) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [lastScroll, setLastScroll] = useState(0);

    const handleScroll = (e: any) => {
      if (e.target.scrollTop === 0 && isStopPagination === false) {
        if (messageData.length >= 15) {
          setLastId(messageData[0].id);
          setLastScroll(e.target.scrollHeight);
        }
      }
    };

    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight - lastScroll,
        });
      }
    }, [lastId]);

    const renderMessages = useCallback(() => {
      return messageData?.map((item: MessageType) => (
        <MessageItem users={users} message={item} />
      ));
    }, [messageData, users]);

    if (showStub) {
      return <Stub text="No messages yet" />;
    }

    if (!!messageData.length === false) {
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        onScroll={handleScroll}
        sx={{
          flexGrow: 1,
          maxHeight: isActive ? "705px" : "100%",
          overflowY: "scroll",
        }}
        ref={scrollRef}
      >
        {renderMessages()}
        <Box ref={ref} />
      </Box>
    );
  }
);

export default MessageContainer;
