import { FC } from "react";
import {
  Box,
  Button,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, FormSpy } from "react-final-form";

type PostToolBarProps = {
  title?: string;
  isEdit?: boolean;
  id?: number;
};

const PostToolBar: FC<PostToolBarProps> = ({
  title = "",
  isEdit = true,
  id,
}) => {
  return (
    <Toolbar>
      <Box
        sx={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="span">
          {isEdit ? `Post ${id ? `#${id}` : ""}: ${title}` : "Create Post"}
        </Typography>
      </Box>
      <Box>
        <Field
          subscription={{ value: true }}
          type="checkbox"
          name="data.is_active"
        >
          {({ input }) => (
            <Tooltip title="Is Active">
              <Switch {...input} defaultChecked />
            </Tooltip>
          )}
        </Field>
      </Box>
      <FormSpy
        subscription={{
          dirty: true,
          pristine: true,
          touched: true,
        }}
      >
        {({ pristine, touched, form }) => (
          <>
            <Button
              sx={{ marginLeft: "auto", marginRight: "1rem" }}
              color="primary"
              onClick={() => form.reset()}
            >
              Reset
            </Button>
            <Button
              type="submit"
              disabled={pristine || !touched}
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </>
        )}
      </FormSpy>
    </Toolbar>
  );
};

export default PostToolBar;
