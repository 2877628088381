import React, { FC, useCallback, useMemo } from "react";
import { SupportChatContext } from "../../context/SupportChatContext/ChatContext";
import {
  MessageType,
  ResponseMeta,
  ThreadItemType,
} from "../../pages/SupportChat/SupportChatType";
import useCommon from "../../hooks/useCommon";

type SupportChatProviderProps = {
  children: React.ReactNode;
};

const SupportChatProvider: FC<SupportChatProviderProps> = ({ children }) => {
  const [threads, setThreads] = React.useState<ThreadItemType[]>([]);
  const [selectedThread, setSelectedThread] =
    React.useState<ThreadItemType | null>(null);
  const [messages, setMessages] = React.useState<MessageType[]>([]);
  const { profile } = useCommon();

  const setThreadsState = useCallback(
    (responseData: { data: ThreadItemType[]; meta: ResponseMeta }) => {
      if (responseData.meta.current_page === 1) {
        setThreads(responseData.data);
      } else {
        setThreads((prev) => [...prev, ...responseData.data]);
      }
    },
    [setThreads]
  );

  const setSelectedThreadState = useCallback(
    (threadData: ThreadItemType | null) => {
      setSelectedThread(threadData);
    },
    []
  );

  const setMessagesState = useCallback(
    (messagesData: MessageType[]) => {
      const data = messagesData.reverse();
      setMessages((prev) => [...data, ...prev]);
    },
    [selectedThread, threads]
  );

  const addMessage = useCallback(
    (message: MessageType, isSocket: boolean) => {
      if ((profile !== null && profile.id !== message.user_id) || !isSocket) {
        setMessages((prev) => [...prev, message]);
      }
    },
    [profile]
  );

  const resetMessages = useCallback(() => {
    setMessages([]);
  }, []);

  const resetSelectedThread = useCallback(() => {
    setSelectedThread(null);
  }, []);

  const setSeenMessage = useCallback(() => {
    setMessages((prev) => prev.map((item) => ({ ...item, status: "seen" })));
  }, []);

  const addThread = useCallback((thread: ThreadItemType) => {
    setThreads((prev) => [thread, ...prev]);
  }, []);

  const sortThreads = useCallback((id: number) => {
    setThreads((prev) => [
      ...prev.filter((item) => item.id === id),
      ...prev.filter((item) => item.id !== id),
    ]);
  }, []);

  const setThreadCounter = useCallback((id: number, count: number) => {
    setThreads((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, count_unread_messages: count };
        }
        return item;
      })
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      setThreadsState,
      setSelectedThreadState,
      threads,
      selectedThread,
      setMessagesState,
      messages,
      addMessage,
      resetMessages,
      resetSelectedThread,
      setSeenMessage,
      addThread,
      sortThreads,
      setThreadCounter,
    }),
    [
      setThreadsState,
      setSelectedThreadState,
      threads,
      selectedThread,
      setMessagesState,
      messages,
      addMessage,
      resetMessages,
      resetSelectedThread,
      setSeenMessage,
      addThread,
      sortThreads,
      setThreadCounter,
    ]
  );

  return (
    <SupportChatContext.Provider value={contextValue}>
      {children}
    </SupportChatContext.Provider>
  );
};

export default SupportChatProvider;
