import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import useChat from "../../hooks/useChat";
import ThreadList from "./ThreadList/ThreadList";
import ChatContainer from "./ChatContainer/ChatContainer";
import useCommon from "../../hooks/useCommon";
import useGlobalCounters from "../../hooks/useGlobalCounters";
import Stub from "../../components/Stub";

const SupportChat = () => {
  const api = useApi();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadCrumbs } = useCommon();
  const { setThreadsState, threads } = useChat();
  const { setSupportCounter } = useGlobalCounters();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isStopPagination, setIsStopPagination] = useState(false);

  const [showStub, setShowStub] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);

  const getThreads = async () => {
    try {
      const { data } = await api.get(
        `/admin/threads/support?&query=${query}&page=${page}`
      );
      setPage(data.meta.current_page + 1);
      setThreadsState(data);
      if (data.data.length < 15) {
        setIsStopPagination(true);
      }
      if (page === 1 && data.data.length === 0 && query === "") {
        setShowStub(true);
      }

      if (page === 1 && data.data.length === 0 && query !== "") {
        setShowNotFound(true);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const debounceSearch = useDebouncedCallback(() => {
    setShowNotFound(false);
    getThreads();
  }, 1000);

  const handleChangeSearch = (value: string) => {
    setQuery(value);

    setPage(1);
    debounceSearch();
  };

  const getGlobalCounters = async () => {
    try {
      const { data } = await api.get("/admin/global-counters");
      setSupportCounter?.(data.count_unread_message);
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Support chat" }]);
    getThreads();
    getGlobalCounters();
    return () => {};
  }, []);

  if (threads.length === 0) {
    if (showStub) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 120px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stub text="You dont have active chat" />
        </Box>
      );
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: `calc(100vh - 120px)`,
      }}
    >
      <ThreadList
        data={threads}
        handleChangeSearch={handleChangeSearch}
        isStopPagination={isStopPagination}
        getThreadList={getThreads}
        showNotFoundStub={showNotFound}
      />
      {id && <ChatContainer />}
    </Box>
  );
};

export default SupportChat;
