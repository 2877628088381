import { createContext } from "react";

export type BreadCrumbs = {
  label: string;
  url?: string;
};
export type User = {
  activated_at: string;
  avatar: { additional_info: { size_urls: { avatar_icon: string } } };
  counters: { count_incoming: number };
  count_incoming: number;
  default_package_id: number;
  device_tokens: string[];
  email: string;
  first_name: "string";
  full_name: string;
  gender: string;
  hubs: any;
  id: number;
  is_package_created: boolean;
  is_trial: boolean;
  last_name: string;
  middle_name: string;
  package: any;
  package_canceled: any;
  package_end: string;
  package_id: number;
  phone: string;
  role: string;
  subscription: any;
  timezone: string;
  timezone_name: string;
  view_data: any;
};

interface ICommonContext {
  token: string;
  isAuth: boolean;
  breadCrumbs: BreadCrumbs[];
  setToken?: (token: string) => void | undefined;
  setAuth?: (auth: boolean) => void | undefined;
  setBreadCrumbs?: (data: BreadCrumbs[]) => void | undefined;
  profile: User | null;
  setProfile?: (user: User | null) => void | undefined;
}

export const CommonContext = createContext<ICommonContext>({
  token: "",
  isAuth: false,
  breadCrumbs: [],
  profile: null,
});
