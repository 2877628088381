import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { useDebouncedCallback } from "use-debounce";
import {
  Box,
  Button,
  Card,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import useCommon from "../../hooks/useCommon";
import { useApi, useFetcher } from "../../hooks/useApi";
import UsersRow from "./UsersRow";
import { UsersType } from "./Users.type";
import { timeSelectConfig } from "../Analytics/analytic.config";
import { AnalyticTimePreset } from "../Analytics/analytic.enum";

const Users = () => {
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const api = useApi();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState<"desc" | "asc">("asc");
  const [sortBy, setSorBy] = useState("");
  const [search, setSearch] = useState("");
  const [startDay, setStartDay] = useState("");
  const [datePreset, setDatePreset] = useState(AnalyticTimePreset.allTime);
  const [endDay, setEndDay] = useState("");
  const { setBreadCrumbs } = useCommon();
  const { enqueueSnackbar } = useSnackbar();

  const replaceUndefinedOrNull = (key: string, value: any) => {
    if (value === null || value === undefined || value === "") {
      return undefined;
    }

    return value;
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Users" }]);
  }, [setBreadCrumbs]);

  const searchParams = useCallback(() => {
    return JSON.parse(
      JSON.stringify(
        {
          per_page: perPage,
          order_by: orderBy.toLocaleUpperCase(),
          page: page + 1,
          sort_by: sortBy,
          search: search.replace("+", ""),
          start_date: startDay,
          end_date: endDay,
        },
        replaceUndefinedOrNull
      )
    );
  }, [perPage, orderBy, page, sortBy, search, startDay, endDay]);

  const { data, mutate } = useSWR(["/users", searchParams()], fetcher);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (property: string) => () => {
    const isAscending = sortBy === property && orderBy === "asc";
    setSorBy(property);
    setOrderBy(isAscending ? "desc" : "asc");
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = useDebouncedCallback((value) => {
    setSearch(value);
    setSorBy("");
    setOrderBy("asc");
  }, 1000);

  const clearSearchParams = () => {
    setSearch("");
    setStartDay("");
    setEndDay("");
    setDatePreset(AnalyticTimePreset.allTime);
  };

  const handleDeleteUser = async (id: number) => {
    try {
      await api.delete(`/users/${id}`);
      await mutate();
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleOpenChat = async (id: number) => {
    try {
      const res = await api.post(`/admin/users/${id}/threads`);
      navigate(`/chat_support/${res.data.id}`);
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useMemo(() => {
    if (datePreset === AnalyticTimePreset.allTime) {
      setEndDay("");
      setStartDay("");
    }
    if (datePreset === AnalyticTimePreset.today) {
      setStartDay(moment().format("YYYY-MM-DD"));
      setEndDay(moment().format("YYYY-MM-DD"));
    }
    if (datePreset === AnalyticTimePreset.yesterday) {
      setStartDay(moment().subtract(1, "day").format("YYYY-MM-DD"));
      setEndDay(moment().subtract(1, "day").format("YYYY-MM-DD"));
    }
    if (datePreset === AnalyticTimePreset.week) {
      setStartDay(moment().subtract(1, "week").format("YYYY-MM-DD"));
      setEndDay(moment().format("YYYY-MM-DD"));
    }
    if (datePreset === AnalyticTimePreset.month) {
      setStartDay(moment().subtract(1, "month").format("YYYY-MM-DD"));
      setEndDay(moment().format("YYYY-MM-DD"));
    }
  }, [datePreset]);

  const skeletonArray = Array(perPage).fill(Array(8).fill(""));

  return (
    <Box sx={{ width: "100%" }}>
      <Card>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{ width: 220 }}
                id="type_id"
                placeholder="Search..."
                label="Search"
                variant="standard"
                onChange={(e) => handleChangeSearch(e.target.value)}
                fullWidth
              />

              <Box sx={{ width: "200px", marginLeft: "20px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date preset
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    value={datePreset}
                    label="Date preset"
                    onChange={(e) => {
                      setDatePreset(e.target.value as AnalyticTimePreset);
                    }}
                  >
                    {timeSelectConfig.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <TextField
                id="date"
                label="Start date"
                type="date"
                value={startDay}
                variant="standard"
                onChange={(e) => setStartDay(e.target.value)}
                sx={{ width: 150, marginLeft: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="End date"
                variant="standard"
                type="date"
                value={endDay}
                onChange={(e) => setEndDay(e.target.value)}
                sx={{ width: 150, marginLeft: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Fade in={!!search || !!startDay || !!endDay}>
                <Box sx={{ marginLeft: "20px" }}>
                  <IconButton onClick={clearSearchParams} size="small">
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Fade>
            </Box>
            <Button variant="outlined" component={Link} to="/users/create">
              Add
            </Button>
          </Box>
        </Toolbar>

        <TableContainer>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TableSortLabel
                    onClick={handleSort("id")}
                    active={sortBy === "id"}
                    direction={sortBy === "id" ? orderBy : "asc"}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("email")}
                    active={sortBy === "email"}
                    direction={sortBy === "email" ? orderBy : "asc"}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("phone")}
                    active={sortBy === "phone"}
                    direction={sortBy === "phone" ? orderBy : "asc"}
                  >
                    Phone
                  </TableSortLabel>{" "}
                </TableCell>

                <TableCell>Birthday</TableCell>
                <TableCell>Package</TableCell>

                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("last_activated_at")}
                    active={sortBy === "last_activated_at"}
                    direction={sortBy === "last_activated_at" ? orderBy : "asc"}
                  >
                    Last activated at
                  </TableSortLabel>
                </TableCell>
                <TableCell>Last login at</TableCell>
                <TableCell size="small" align="right" />
              </TableRow>
            </TableHead>
            {data ? (
              <TableBody>
                {data.data.map((item: UsersType) => (
                  <UsersRow
                    key={item.id}
                    data={item}
                    handleDeleteUser={handleDeleteUser}
                    handleOpenChat={handleOpenChat}
                  />
                ))}
              </TableBody>
            ) : (
              skeletonArray.map((item, index: number) => (
                <TableRow key={index}>
                  {item.map((el: string[], ind: number) => (
                    <TableCell key={ind} component="th" scope="row">
                      <Skeleton animation="wave" variant="text" height="30px" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data?.meta?.total || 0}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

export default Users;
