import React, { FC, useState } from "react";
import {
  Badge,
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "mui-modal-provider";
import { Emoji } from "emoji-mart";
import { MessageType } from "../SupportChat/SupportChatType";
import ShowFileModal from "../../components/modals/ShowFileModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import EditMessageDialog from "../../components/modals/EditMessageModal";
import ChatReactionModal from "../../components/modals/ChatReactionModal";
type ChatReleaseRowProps = {
  data: MessageType;
  handleDeleteMessage: (id: number) => void;
  handleReloadMessages: () => void;
};

const ChatReleaseRow: FC<ChatReleaseRowProps> = ({
  data,
  handleDeleteMessage,
  handleReloadMessages,
}) => {
  const [open, setOpen] = useState(false);
  const { showModal } = useModal();

  const handleShowModal = (media: any) => {
    const modal = showModal(ShowFileModal, {
      fileName: media.original_filename,
      url: media.additional_info.pdf_url
        ? media.additional_info.pdf_url
        : media.url,
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleOpenConfirmationDialog = (id: number) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete Message ?`,
      body: "Are you sure you want to delete this item?",
      onConfirm: () => {
        handleDeleteMessage(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleOpenEditMessageDialog = (message: MessageType) => {
    const modal = showModal(EditMessageDialog, {
      editData: { body: message.body, files: message.media },
      messageId: message.id,
      handleReloadMessages: () => handleReloadMessages(),
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleOpenChatEmoji = (message: MessageType) => {
    const modal = showModal(ChatReactionModal, {
      messageId: message.id,
      onCancel: () => {
        modal.hide();
      },
    });
  };
  return (
    <>
      <TableRow sx={{ height: "80px" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "300px",
              overflow: "hidden",
            }}
            variant="body2"
          >
            {data.body}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {!!data.reactions.length &&
              data.reactions.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenChatEmoji(data)}
                    size="small"
                  >
                    <Badge
                      badgeContent={item.count}
                      max={999}
                      color="primary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Emoji emoji={item.name} size={23} />
                    </Badge>
                  </IconButton>
                </Box>
              ))}
          </Box>
        </TableCell>

        <TableCell size="small" align="center" component="th" scope="row">
          <Box>
            <IconButton
              size="small"
              onClick={() => handleOpenEditMessageDialog(data)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenConfirmationDialog(data.id)}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography variant="body1">{data.body}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: "20px",
                mb: "20px",
              }}
            >
              {data.media !== null &&
                data.media.map((media) => (
                  <Tooltip title={media.original_filename}>
                    <Box
                      onClick={() => handleShowModal(media)}
                      sx={{
                        borderRadius: "5px",
                        maxWidth: "50px",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <InsertDriveFileIcon />
                      </Box>
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "180px",
                          overflow: "hidden",
                        }}
                      >
                        {media.original_filename}
                      </Typography>
                    </Box>
                  </Tooltip>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ChatReleaseRow;
