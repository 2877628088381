import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import * as yup from "yup";
import { ValidationErrors } from "final-form";
import { useApi } from "../../../../hooks/useApi";
import useCommon from "../../../../hooks/useCommon";
import DefaultForm from "../../../../components/form/DefaultForm";
import UserToolBar from "../UserToolBar";
import UserFields from "../UserFields";
import { errorService } from "../../../../service/ErrorService/ErrorService";

type CreateUserForm = {
  first_name: string;
  last_name: string;
  email: string;
  birth_day: string;
  gender: string;
  password: string;
  password_confirmation: string;
};

const CreateUser = () => {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const { setBreadCrumbs } = useCommon();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadCrumbs?.([
      { label: "Users", url: "/users" },
      { label: `User #${id}` },
    ]);
  }, [id, setBreadCrumbs]);

  const initialValue = {
    first_name: "",
    last_name: "",
    email: "",
    birth_day: "",
    gender: "",
    password: "",
    password_confirmation: "",
  };

  const onSubmit = async (values: CreateUserForm) => {
    try {
      const { data } = await api.post(`/users`, {
        ...values,
        birth_day: values.birth_day,
      });
      navigate(`/users/${data.id}`);
    } catch (e: any) {
      const errors = errorService(e.response.data.errors);
      enqueueSnackbar("Something went wrong", { variant: "error" });

      return errors;
    }
  };

  const validationSchema: yup.SchemaOf<CreateUserForm> = yup.object().shape({
    first_name: yup.string().min(2).required(),
    last_name: yup.string().min(2).required(),
    email: yup.string().email().min(2).required(),
    birth_day: yup.string().required(),
    gender: yup.string().min(2).required(),
    password: yup.string().min(2).required(),
    password_confirmation: yup.string().min(2).required(),
  });

  const validate = (
    values: CreateUserForm
  ): Promise<ValidationErrors | undefined> =>
    validationSchema
      .validate(values, { abortEarly: false })
      .then(() => undefined)
      .catch((error: yup.ValidationError) => {
        const errors: { [value: string]: string } = {};
        error.inner.forEach((e) => {
          if (e.path) {
            errors[e.path] = e.message;
          }
        });
        return errors;
      });

  return (
    <Box sx={{ width: "100%" }}>
      <DefaultForm<CreateUserForm>
        validate={validate}
        isValidate
        data={initialValue}
        onSubmit={onSubmit}
      >
        <UserToolBar userFullName="Create User" />
        <UserFields isCreate />
      </DefaultForm>
    </Box>
  );
};
export default CreateUser;
