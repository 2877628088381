import { ChangeEvent, useState } from "react";
import { Box, Input, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useApi } from "../../../../../../hooks/useApi";
import { copyToClipboard } from "../../../../../../utils/copyToClipboard";

const UploadFileToPost = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", fileList[0], fileList[0].name);
      const res = await api.post("/blog/posts/photo", formData);
      const fileUrl = res.data.file_url;
      await copyToClipboard(fileUrl);
      enqueueSnackbar("File successfully uploaded and copied to clipboard", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography>Upload photo for post</Typography>
      <Box sx={{ marginTop: "20px" }}>
        <label htmlFor="post-photo">
          <Input
            onChange={uploadFile}
            sx={{ display: "none" }}
            id="post-photo"
            type="file"
          />
          <LoadingButton loading={loading} variant="outlined" component="span">
            Upload
          </LoadingButton>
        </label>
      </Box>
    </Box>
  );
};

export default UploadFileToPost;
